import { combineReducers } from 'redux';
import user, * as fromUser from './userReducer';
import login, * as fromLogin from './loginReducer';
import client, * as fromClient from './clientReducer';
import location, * as fromLocation from './locationReducer';
import dashboard, * as fromDashboard from './dashboardReducer';
import citation, * as fromCitation from './citationReducer';


export default combineReducers({
  user,
  login,
  client,
  location,
  dashboard,
  citation,
});

export const getUser = (state) => fromUser.getUser(state.user);
export const getLogin = (state) => fromLogin.getLogin(state.login);
export const getClient = (state) => fromClient.getClient(state.client);
export const getLocation = (state) => fromLocation.getLocation(state.location);
export const getDashboard = (state) => fromDashboard.getDashboard(state.dashboard);
export const getCitation = (state) => fromCitation.getCitation(state.citation);
