// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  visitorCount: [],
  gettingVisitorCount: false,
  gotVisitorCount: false,
  realTimeCount: [],
  totalVisitsCount: [],
  chosenDestinations: [],
  dailyScans: [],
  gettingDailyScans: false,
  gotDailyScans: false,
  citations: [],
  gettingCitations: false,
  gotCitations: false,
  scans: [],
  warnings: [],
  disputes: [],
  gettingDisputes: false,
  gotDisputes: false,
  statusFilter: 'all'
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.GETTING_VISITOR_COUNT:
      return {
        ...state, gettingVisitorCount: true, gotVisitorCount: false
      };
    case actionConstants.GOT_VISITOR_COUNT:
      return {
        ...state, gettingVisitorCount: false, gotVisitorCount: true, visitorCount: action.payload
      };
    case actionConstants.GOT_REAL_TIME_COUNT:
      return {
        ...state, realTimeCount: action.payload
      };
    case actionConstants.GOT_TOTAL_VISITS_COUNT:
      return {
        ...state, totalVisitsCount: action.payload
      };  
    case actionConstants.GOT_CHOSEN_DESTINATIONS:
      return {
        ...state, chosenDestinations: action.payload
      };
    case actionConstants.GETTING_DAILY_SCANS:
      return {
        ...state, gettingDailyScans: true, gotDailyScans: false
      };
    case actionConstants.GOT_DAILY_SCANS:
      return {
        ...state, gettingDailyScans: false, gotDailyScans: true, dailyScans: action.payload
      };  
    case actionConstants.GETTING_CITATIONS:
      return {
        ...state, gettingCitations: true, gotCitations: false
      };
    case actionConstants.GOT_CITATIONS:
      return {
        ...state, gettingCitations: false, gotCitations: true, citations: action.payload
      };
    case actionConstants.GETTING_DISPUTES:
      return {
        ...state, gettingDisputes: true, gotDisputes: false
      };
    case actionConstants.GOT_DISPUTES:
      return {
        ...state, gettingDisputes: false, gotDisputes: true, disputes: action.payload?.filter(citation => citation.ticket_type !== 'warning'),
      };  
    case actionConstants.GOT_SCANS:
      return {
        ...state, scans: action.payload
      };
    case actionConstants.SET_STATUS_FILTER:
      return {
        ...state, statusFilter: action.payload
      };   
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getDashboard = ({
  gettingVisitorCount, gotVisitorCount, visitorCount, realTimeCount, totalVisitsCount, chosenDestinations, gettingDailyScans, gotDailyScans, dailyScans, gettingCitations, gotCitations, citations, scans, warnings, gettingDisputes, gotDisputes, disputes, statusFilter 
}) => ({
  gettingVisitorCount,
  gotVisitorCount,
  visitorCount,
  realTimeCount,
  totalVisitsCount,
  chosenDestinations,
  gettingDailyScans,
  gotDailyScans,
  dailyScans,
  gettingCitations,
  gotCitations,
  citations,
  scans,
  warnings,
  gettingDisputes,
  gotDisputes,
  disputes,
  statusFilter
});
