/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect, useRef} from "react";
import {
  Container,
  Row,
  Card,
  FormCheckbox,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Col,
  Form,
  FormFeedback,
  Button,
  FormInput,
  FormTextarea,
} from "shards-react";
import {useParams} from "react-router-dom";
import { getUserLocale } from 'get-user-locale';
import GooglePlacesAutocomplete, {geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeIcon from '@mui/icons-material/Home';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import PrintIcon from '@mui/icons-material/Print';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { faCheck, faEllipsisH, faMessa } from '@fortawesome/free-solid-svg-icons';
import PageTitle from "../../components/common/PageTitle";
import { accessCheck } from "../../utils/tools";

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();

const EditLocation = (props) => {
  let {locationId} = useParams();
  const [location, setLocation] = useState(props.locations.find(location => location.id === locationId));
  const [client, setClient] = useState(props.user.type === 'super' ? null : props.clients[0].id);
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [locations, setLocations] = useState(props.locations);
  const [addedLocation, setAddedLocation] = useState(false);
  const [validName, setValidName] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zip, setZip] = useState(null);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [earlyBirdDays, setEarlyBirdDays] = useState(14)
  const [earlyBirdPercentage, setEarlyBirdPercentage] = useState(25)
  const [penaltyDays, setPenaltyDays] = useState(45)
  const [penaltyPercentage, setPenaltyPercentage] = useState(25)
  const [writeOffDays, setWriteOffDays] = useState(200)
  const [configurationStage, setConfigurationStage] = useState(1)
  const [violations, setViolations] = useState([{name: null, amount: null, code: null}])
  const [officerAlertMessage, setOfficerAlertMessage] = useState('This vehicle is a frequent violator')
  const [violationRules, setViolationRules] = useState({citation_rule: {citations: 3, months: 3}})
  const [footerText, setFooterText] = useState('')
  const [citationTerm, setCitationTerm] = useState('Parking Violation')
  const [warningTerm, setWarningTerm] = useState('Violation Warning')
  const [firstParagraph, setFirstParagraph] = useState('')
  const [parklyncApiKey, setParklyncApiKey] = useState(null)
  const [parklyncLocationId, setParklyncLocationId] = useState(null)
  const [spotheroLocationId, setSpotheroLocationId] = useState(null)
  const [t2LocationId, setT2LocationId] = useState(null)
  const [parkpliantUsername, setParkpliantUsername] = useState(null)
  const [parkpliantPassword, setParkpliantPassword] = useState(null)
  const [parkpliantIanaTimezone, setParkpliantIanaTimezone] = useState(null)
  const [parkpliantSendDays, setParkpliantSendDays] = useState(90)
  const [parkMobileUsername, setParkMobileUsername] = useState(null)
  const [parkMobilePassword, setParkMobilePassword] = useState(null)
  const [parkMobileApiKey, setParkMobileApiKey] = useState(null)
  const [parkMobileLocationId, setParkMobileLocationId] = useState(null)
  const [parkWhizLocationId, setParkWhizLocationId] = useState(null)
  const params = (new URL(document.location)).searchParams;
  const returnTo = params.get('return-to')
  const addressSelector = useRef(null);
  const [subtitle, setSubtitle] = useState('Step 1')

  useEffect(() => {
    props.getLiveLocations(props.user.id)
    !accessCheck(props.user, 'locations') && (window.location.href = '/') 
  }, [])

  useEffect(() => {
    if (location) {
      setName(location.name || '')
      setAddress1(location.address1 || '')
      setAddress2(location.address2 || '')
      setCity(location.city || '')
      setState(location.state || '')
      setZip(location.zip || '')
      setLat(location.lat || '')
      setLon(location.lon || '')
      setClient(location.client || null)
      setActive(!!location.active)
      setEarlyBirdDays(location.early_bird?.offer_days || 0)
      setEarlyBirdPercentage(location.early_bird?.percentage || 0)
      setPenaltyDays(location.penalty?.days || 0)
      setPenaltyPercentage(location.penalty?.percentage || 0)
      setWriteOffDays(typeof location.write_off_days === 'number' ? location.write_off_days : 200)
      setViolations(location.violation_fees || [{name: null, amount: null, code: null}])
      setOfficerAlertMessage(location.officer_alert_message || 'This vehicle is a frequent violator')
      setViolationRules(location.violation_rules || {citation_rule: {citations: 3, months: 3}})
      setFooterText(location.footer_text || '')
      setCitationTerm(location.citation_term || 'Parking Violation')
      setWarningTerm(location.warning_term || 'Violation Warning')
      setFirstParagraph(location.first_paragraph || 'Your vehicle was found to be in violation of the terms of parking at ' + location.name + ', ' + location.address1 + ', ' + location.city + ', ' + location.state + ' ' + location.zip)
      setParklyncApiKey(location.parklync_api_key || null)
      setParklyncLocationId(location.parklync_location_id || null)
      setSpotheroLocationId(location.spothero_location_id || null)
      setT2LocationId(location.t2_location_id || null)
      setParkpliantUsername(location.parkpliant_username || null)
      setParkpliantPassword(location.parkpliant_password || null)
      setParkpliantIanaTimezone(location.iana_timezone || null)
      setParkpliantSendDays(location.parkpliant_days || 90)
      setParkMobileUsername(location.parkmobile_username || null)
      setParkMobilePassword(location.parkmobile_password || null)
      setParkMobileApiKey(location.parkmobile_api_key || null)
      setParkMobileLocationId(location.parkmobile_location_id || null)
      setParkWhizLocationId(location.parkwhizz_location_id || null)
    }
  }, [location])

  useEffect(() => {
    if (locationId) {
      setLocation(props.locations.find(location => location.id === locationId))
    }
  }, [locationId])

  useEffect(() => {
    setLocations(props.locations)
  }, [props.locations])

  useEffect(() => {
    setSubtitle('Step ' + (configurationStage !== 6 ? configurationStage : 5))
  }, [configurationStage])

  const setAddressFromSearch = async place => {
    if (place) {
      let geoPlace = await geocodeByPlaceId(place.value.place_id).then(results => results[0]).catch(error => console.log(error))
      let thisLocation = await getLatLng(geoPlace).then(result => result).catch(error => console.log(error))
      let tempName = place.value.structured_formatting.main_text
      let tempAddress1 = place.value.structured_formatting.main_text
      let tempAddress2 = geoPlace.address_components.find(comp => comp.types?.indexOf('neighborhood') !== -1 || comp.types?.indexOf('administrative_area_level_3') !== -1)?.long_name || ''
      let tempCity = geoPlace.address_components.find(comp => comp.types?.indexOf('locality') !== -1 || comp.types?.indexOf('sublocality') !== -1)?.long_name
      let tempState = geoPlace.address_components.find(comp => comp.types?.indexOf('administrative_area_level_1') !== -1)?.short_name
      let tempZip = geoPlace.address_components.find(comp => comp.types?.indexOf('postal_code') !== -1)?.long_name
      setName(tempName)
      setAddress1(tempAddress1)
      setAddress2(tempAddress2)
      setCity(tempCity)
      setState(tempState)
      setZip(tempZip)
      setLat(thisLocation.lat.toString())
      setLon(thisLocation.lng.toString())
      if (!location) {
        setFirstParagraph('Your vehicle was found to be in violation of the terms of parking at ' + tempName + ', ' + tempAddress1 + ', ' + tempCity + ', ' + tempState + ' ' + tempZip)
      }
    } else {
      //setProperty(null);
    }
  }

  const nameNotValid = () => {
    let isValid = true
    if (!name || name.length < 4) {
      isValid = false
    }
    return !isValid
  }

  const clientNotValid = () => {
    let isValid = true
    if (!client) {
      isValid = false
    }
    return !isValid
  }

  const updateViolations = (field, index, value, type) => {
    let tempViolations = [...violations];
    if (type === 'int') {
      if (!value) {
        value = 0
      }
      value = parseInt(value)
    }
    tempViolations[index][field] = value;
    setViolations(tempViolations)
  }

  const addAnotherViolation = () => {
    let tempViolations = [...violations];
    tempViolations.push({name: null, amount: null, code: null})
    setViolations(tempViolations)
  }

  const removeFromViolations = (index) => {
    let tempViolations = [...violations];
    tempViolations.splice(index, 1)
    setViolations(tempViolations)
  }

  const violationsNotValid = () => {
    if (violations.length === 0) {
      return true
    }
    let isValid = true
    violations.map(violation => {
      if (!violation.name || violation.name.length < 4) {
        isValid = false
      }
      if (parseFloat(violation.amount) > 1000) {
        isValid = false
      }
    })
    return !isValid
  }

  const updateViolationRules = (field, subfield, value, type) => {
    let tempViolationRules = {...violationRules};
    if (type === 'int' && value?.length > 0) {
      value = parseInt(value)
    }
    tempViolationRules[field][subfield] = value;
    setViolationRules(tempViolationRules)
  }

  const addAgedBalanceRule = () => {
    let tempViolationRules = {...violationRules};
    tempViolationRules['aged_balance_rule'] = {conditional: 'OR', value: 3};
    setViolationRules(tempViolationRules)
  }

  const addBalanceRule = () => {
    let tempViolationRules = {...violationRules};
    tempViolationRules['balance_rule'] = {conditional: 'OR', value: 400};
    setViolationRules(tempViolationRules)
  }

  const removeAgedBalanceRule = () => {
    let tempViolationRules = {...violationRules};
    delete tempViolationRules.aged_balance_rule;
    setViolationRules(tempViolationRules)
  }

  const removeBalanceRule = () => {
    let tempViolationRules = {...violationRules};
    delete tempViolationRules.balance_rule;
    setViolationRules(tempViolationRules)
  }

  const feesAndPenaltiesNotValid = () => {
    let isValid = true
    if (parseInt(earlyBirdPercentage) > 100) {
      isValid = false
    }
    if (parseInt(penaltyPercentage) > 100) {
      isValid = false
    }
    return !isValid
  }

  const violationRulesNotValid = () => {
    let isValid = true
    if (violationRules.citation_rule.citations?.length === 0) {
      isValid = false
    }
    if (violationRules.citation_rule.months?.length === 0) {
      isValid = false
    }
    if (violationRules.aged_balance_rule?.conditional?.length === 0) {
      isValid = false
    }
    if (violationRules.aged_balance_rule?.value?.length === 0) {
      isValid = false
    }
    if (violationRules.balance_rule?.conditional?.length === 0) {
      isValid = false
    }
    if (violationRules.balance_rule?.value?.length === 0) {
      isValid = false
    }
    return !isValid
  }

  const addLocation = () => {
    props.addLocation({client, active, name, address1, address2, city, state, zip, lat, lon, earlyBirdDays, earlyBirdPercentage, penaltyDays, penaltyPercentage, writeOffDays, violations, officerAlertMessage, violationRules, footerText, citationTerm, firstParagraph, warningTerm, t2LocationId, parklyncApiKey, parklyncLocationId, spotheroLocationId, parkpliantUsername, parkpliantPassword, parkpliantIanaTimezone, parkpliantSendDays, parkMobileUsername, parkMobilePassword, parkMobileApiKey, parkMobileLocationId, parkWhizLocationId}, () => window.location.href='/locations')
  }

  const updateLocation = () => {
    props.editLocation(location, {client, active, name, address1, address2, city, state, zip, lat, lon, earlyBirdDays, earlyBirdPercentage, penaltyDays, penaltyPercentage, writeOffDays, violations, officerAlertMessage, violationRules, footerText, citationTerm, firstParagraph, warningTerm, t2LocationId, parklyncApiKey, parklyncLocationId, spotheroLocationId, parkpliantUsername, parkpliantPassword, parkpliantIanaTimezone, parkpliantSendDays, parkMobileUsername, parkMobilePassword, parkMobileApiKey, parkMobileLocationId, parkWhizLocationId}, () => window.location.href='/locations')
  }

  const renderConfiguratorStage = () => {
    switch (configurationStage) {
      case 1:
        return renderStage1();
      case 2:
        return renderStage2();
      case 3:
        return renderStage3();
      case 4:
        return renderStage4();
      case 5:
      default:  
        return renderStage5();  
    }
  }

  const renderViolations = () => {
    return violations.map((violation, idx) => (
      <Row className={'mb-2'}>
        <Col>
          <Card className={'p-3 pb-4'}>
            <Row>
              <Col xs={4}>
                <label htmlFor="feViolationName" className="form-label-custom">Violation name</label>
                <FormInput
                  id={'violation-' + idx}
                  placeholder={'Violation name'}
                  value={violation.name}
                  onChange={(event) => updateViolations('name', idx, event.target.value)}
                />
              </Col>
              <Col xs={4}>
                <label htmlFor="feViolationAmount" className="form-label-custom">Amount (0 or blank for Warning)</label>
                <FormInput
                  id={'amount-' + idx}
                  placeholder={'Amount'}
                  value={violation.amount}
                  onChange={(event) => updateViolations('amount', idx, event.target.value.replace(/[^.\d]/g, ''), 'int')}
                />
              </Col>
              <Col xs={4}>
                <label htmlFor="feViolationCode" className="form-label-custom">Violation Code (displays on citation)</label>
                <FormInput
                  id={'code-' + idx}
                  placeholder={'Code'}
                  value={violation.code}
                  onChange={(event) => updateViolations('code', idx, event.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={'text-right'} style={{paddingTop: 8}}>
                {idx > 0 && (
                  <Button
                    className="mr-1"
                    theme="outline-secondary"
                    onClick={() => removeFromViolations(idx)}
                  >Remove</Button>
                )}
                <Button
                  theme="outline-accent"
                  onClick={() => addAnotherViolation()}
                >Add another</Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    ))
  }

  const renderStage1 = () => {
    return (
      <>
        <Card className={'mb-0'}>
          <Container fluid>
            <Row>
              <Col>
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Form>
                          <Row form style={{borderBottom: "1px solid #e1e5eb", paddingBottom: 16, marginBottom: 16}}>
                            <Col md="12" className="form-group">
                              <label htmlFor="feFindProperty" className="form-label-custom">Address</label><br />
                              <span style={{fontSize: 12}}>Find a property using our Google integration.</span><br /><br />
                              <GooglePlacesAutocomplete
                                ref={addressSelector}
                                apiKey="AIzaSyAs4SS_IliPH7OVWUd5rz57mMGQOm3aswQ"
                                selectProps={{
                                  value: location ? location.name : null,
                                  onChange: setAddressFromSearch,
                                  placeholder: "Start typing an address..",
                                  isClearable: true,
                                }}
                                apiOptions={{ language: 'US', region: 'US' }}
                                autocompletionRequest={{componentRestrictions: {country: 'US'}}}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form>
                                <Row form>
                                  <Col md="12" className="form-group">
                                    <label htmlFor="feName" className="form-label-custom">Location name</label>
                                    <FormInput
                                      id='feName'
                                      onChange={event => setName(event.target.value)}
                                      placeholder='Location name'
                                      value={name}
                                      invalid={validName === false}
                                    />
                                    <FormFeedback>Please enter a valid name.</FormFeedback>
                                  </Col>
                                </Row>               
                              </Form>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg='6'>
                              <Form>
                                <Row form>
                                  <Col md="12" className="form-group">
                                    <label htmlFor="feAddress1" className="form-label-custom">Address 1</label>
                                    <FormInput
                                      id="feAddress1"
                                      placeholder="Address 1"
                                      value={address1}
                                      onChange={(event) => setAddress1(event.target.value)}
                                    />
                                  </Col>
                                </Row>                        
                              </Form>
                            </Col>
                            <Col lg='6'>
                              <Form>
                                <Row form>
                                  <Col md="12" className="form-group">
                                    <label htmlFor="feAddress2" className="form-label-custom">Address 2</label>
                                    <FormInput
                                      id="feAddress2"
                                      placeholder="Address 2"
                                      value={address2}
                                      onChange={(event) => setAddress2(event.target.value)}
                                    />
                                  </Col>
                                </Row>                        
                              </Form>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg='6'>
                              <Form>
                                <Row form>
                                  <Col md="12" className="form-group">
                                    <label htmlFor="feCity" className="form-label-custom">City</label>
                                    <FormInput
                                      id="feCity"
                                      placeholder={'City'}
                                      value={city}
                                      onChange={(event) => setCity(event.target.value)}
                                    />
                                  </Col>
                                </Row>                        
                              </Form>
                            </Col>
                            <Col lg='6'>
                              <Form>
                                <Row form>
                                  <Col md="12" className="form-group">
                                    <label htmlFor="feState" className="form-label-custom">State</label>
                                    <FormInput
                                      id="feState"
                                      placeholder={'State'}
                                      value={state}
                                      onChange={(event) => setState(event.target.value)}
                                    />
                                  </Col>
                                </Row>                        
                              </Form>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg='6'>
                              <Form>
                                <Row form>
                                  <Col md="12" className="form-group">
                                    <label htmlFor="feCity" className="form-label-custom">Zip</label>
                                    <FormInput
                                      id="feZip"
                                      placeholder={'Zip'}
                                      value={zip}
                                      onChange={(event) => setZip(event.target.value)}
                                    />
                                  </Col>
                                </Row>                        
                              </Form>
                            </Col>
                            <Col></Col>
                          </Row>                   
                        </Form>
                      </Col>
                    </Row>
                    {props.user.type === 'super' && (
                      <Row>
                        <Col>
                          <Form>
                            <Row form>
                              <Col md="12" className="form-group">
                                <label htmlFor="feClient" className="form-label-custom">Client</label>
                                <FormSelect id="feClient" value={client} onChange={event => setClient(event.target.value)}>
                                <option value={null}>Select</option>
                                  {props.clients.filter(client => ((!location || !location.id) && client.active) || (location && location.id && client)).sort((a, b) => a.name.localeCompare(b.name)).map(client => (
                                    <option value={client.id}>{client.name}</option>
                                  ))}
                                </FormSelect>
                              </Col>
                            </Row>               
                          </Form>
                        </Col>
                      </Row>
                    )}
                    <Row style={{marginTop: 16, marginBottom: 16}}>
                      <Col>
                        <Form>
                          <Row form>
                            <Col md="12" className="form-group">
                              <label htmlFor="feName" className="form-label-custom">Active</label>
                              <FormCheckbox
                                  toggle
                                  checked={active}
                                  onChange={() => setActive(!active)}
                                >
                              </FormCheckbox>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </Row>
          </Container>
        </Card>
        <Row className={'mt-4'}>
          <Col>
            <Form>
              <Row form>
                <Col md="12" className="form-group text-right">
                <Button
                  className="mr-1"
                  theme="secondary"
                  onClick={() => window.location.href='/locations'}
                >Cancel</Button>
                <Button
                  theme="accent"
                  disabled={nameNotValid() || clientNotValid()}
                  onClick={() => {
                    if (!!location) {
                      updateLocation()
                    } else {
                      setConfigurationStage(2)
                    }
                  }}
                >{!!location ? 'Save' : 'Next'}</Button>
                </Col>
              </Row>                        
            </Form>
          </Col>
        </Row>
      </>
    );
  }

  const renderStage2 = () => {
    return (
      <>
        <Row className={'mb-4'}>
          <Col md={4}>
            <Card className={'p-3 pb-4'} style={{height: 170}}>
              <Row>
                <Col style={{textAlign: 'center'}}><span style={{fontSize: 11}}>EARLY PAYMENT DISCOUNT</span></Col>
              </Row>
              <Row style={{marginTop: 16, marginBottom: 8}}>
                <Col xs={6} className={'pr-0'} style={{paddingTop: 4}}><span style={{fontSize: 11}}>If paid within</span></Col>
                <Col xs={2} className={'pr-0 pl-0'}>
                  <FormInput
                    id="paidWithin"
                    value={earlyBirdDays}
                    onChange={(event) => setEarlyBirdDays(event.target.value.replace(/\D/, ''))}
                    style={{paddingLeft: 5, paddingRight: 5}}
                  />
                </Col>
                <Col xs={4} style={{paddingTop: 4}}><span style={{fontSize: 11}}>days</span></Col>
              </Row>
              <Row>
                <Col xs={6} className={'pr-0'} style={{paddingTop: 4}}><span style={{fontSize: 11}}>Apply a discount of</span></Col>
                <Col xs={2} className={'pr-0 pl-0'}>
                  <FormInput
                    id="paidPercentage"
                    value={earlyBirdPercentage}
                    onChange={(event) => setEarlyBirdPercentage(event.target.value.replace(/\D/, ''))}
                    style={{paddingLeft: 5, paddingRight: 5}}
                  />
                </Col>
                <Col xs={4} style={{paddingTop: 4}}><span style={{fontSize: 11}}>percent</span></Col>
              </Row>
            </Card>
          </Col>
          <Col md={4}>
            <Card className={'p-3 pb-4'} style={{height: 170}}>
              <Row>
                <Col style={{textAlign: 'center'}}><span style={{fontSize: 11}}>PENALTY</span></Col>
              </Row>
              <Row style={{marginTop: 16, marginBottom: 8}}>
                <Col xs={6} className={'pr-0'} style={{paddingTop: 4}}><span style={{fontSize: 11}}>If NOT paid within</span></Col>
                <Col xs={2} className={'pr-0 pl-0'}>
                  <FormInput
                    id="notPaidWithin"
                    value={penaltyDays}
                    onChange={(event) => setPenaltyDays(event.target.value.replace(/\D/, ''))}
                    style={{paddingLeft: 5, paddingRight: 5}}
                  />
                </Col>
                <Col xs={4} style={{paddingTop: 4}}><span style={{fontSize: 11}}>days</span></Col>
              </Row>
              <Row>
                <Col xs={6} className={'pr-0'} style={{paddingTop: 4}}><span style={{fontSize: 11}}>Increase by</span></Col>
                <Col xs={2} className={'pr-0 pl-0'}>
                  <FormInput
                    id="penaltyPercentage"
                    value={penaltyPercentage}
                    onChange={(event) => setPenaltyPercentage(event.target.value.replace(/\D/, ''))}
                    style={{paddingLeft: 5, paddingRight: 5}}
                  />
                </Col>
                <Col xs={4} style={{paddingTop: 4}}><span style={{fontSize: 11}}>percent</span></Col>
              </Row>
            </Card>
          </Col>
          <Col md={4}>
            <Card className={'p-3 pb-4'} style={{height: 170}}>
              <Row>
                <Col style={{textAlign: 'center'}}><span style={{fontSize: 11}}>WRITE OFF</span></Col>
              </Row>
              <Row style={{marginTop: 16, marginBottom: 8}}>
                <Col xs={6} className={'pr-0'} style={{paddingTop: 4}}><span style={{fontSize: 11}}>If NOT paid within</span></Col>
                <Col xs={2} className={'pr-0 pl-0'}>
                  <FormInput
                    id="writeOffWithin"
                    value={writeOffDays}
                    onChange={(event) => setWriteOffDays(event.target.value.replace(/\D/, ''))}
                    style={{paddingLeft: 5, paddingRight: 5}}
                  />
                </Col>
                <Col xs={4} style={{paddingTop: 4}}><span style={{fontSize: 11}}>days</span></Col>
              </Row>
              <Row>
                <Col xs={12} className={'pr-0'} style={{paddingTop: 4}}><span style={{fontSize: 11}}>Write off the debt</span></Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {renderViolations()}
        <Row className={'mt-4'}>
          <Col>
            <Form>
              <Row form>
                <Col md="12" className="form-group text-right">
                  <Button
                    className="mr-1"
                    theme="secondary"
                    onClick={() => window.location.href='/locations'}
                  >Cancel</Button>
                  <Button
                    theme="accent"
                    disabled={violationsNotValid() || feesAndPenaltiesNotValid()}
                    onClick={() => {
                      if (!!location) {
                        updateLocation()
                      } else {
                        setConfigurationStage(3)
                      }
                    }}
                  >{!!location ? 'Save' : 'Next'}</Button>
                </Col>
              </Row>                        
            </Form>
          </Col>
        </Row>
      </>
    );
  }

  const renderStage3 = () => {
    return (
      <>
        <Row className={'mb-2'}>
          <Col>
            <Card className={'p-3 pb-4'}>
              <Row>
                <Col xs={12}>
                  <label htmlFor="feCity" className="form-label-custom">Officer alert message</label>
                  <FormInput
                    id={'officerAlert'}
                    placeholder={'Officer alert message'}
                    value={officerAlertMessage}
                    onChange={(event) => setOfficerAlertMessage(event.target.value.substr(0, 400))}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className={'text-right'}>
                  <span style={{fontSize: 10}}>{400 - officerAlertMessage.length} characters remaining</span>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className={'mb-2'}>
          <Col>
            <Card className={'p-3 pb-4'}>
              <Row>
                <Col xs={12}>
                  <label htmlFor="feCity" className="form-label-custom">{'Alert officer (citation rule)'}</label>
                  <Row className={'mt-2'}>
                    <Col lg={6}>
                      <Row className={'pb-2'}>
                        <Col sm={8}  style={{paddingTop: 4}}>
                          <span style={{fontSize: 11}}>IF vehicle has been issued more than</span>
                        </Col>
                        <Col sm={2} className={'pr-0 pl-0'}>
                          <FormInput
                            id={'violationRuleCitationCitations'}
                            value={violationRules.citation_rule.citations}
                            style={{paddingLeft: 5, paddingRight: 5}}
                            onChange={(event) => updateViolationRules('citation_rule', 'citations', event.target.value.replace(/\D/, ''), 'int')}
                          />
                        </Col>
                        <Col sm={2} className={'pr-0'} style={{paddingTop: 4}}>
                          <span style={{fontSize: 11}}>citations</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={8} className={'pr-0'} style={{paddingTop: 4}}>
                          <span style={{fontSize: 11}}>In the past</span>
                        </Col>
                        <Col sm={2} className={'pr-0 pl-0'}>
                          <FormInput
                            id={'violationRuleCitationMonths'}
                            value={violationRules.citation_rule.months}
                            style={{paddingLeft: 5, paddingRight: 5}}
                            onChange={(event) => updateViolationRules('citation_rule', 'months', event.target.value.replace(/\D/, ''), 'int')}
                          />
                        </Col>
                        <Col sm={2} className={'pr-0'} style={{paddingTop: 4}}>
                          <span style={{fontSize: 11}}>months</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Col xs={12} className={'text-right'} style={{paddingTop: 59}}>
                        {!violationRules.aged_balance_rule && (
                          <Button
                            theme="outline-accent"
                            className="mr-1"
                            onClick={() => addAgedBalanceRule()}
                          >Add aged balance rule</Button>
                        )}
                        {!violationRules.balance_rule && (
                          <Button
                            theme="outline-accent"
                            onClick={() => addBalanceRule()}
                          >Add balance rule</Button>
                        )}
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {violationRules?.balance_rule && (
          <Row className={'mb-2'}>
            <Col>
              <Card className={'p-3 pb-4'}>
                <Row>
                  <Col xs={12}>
                    <label htmlFor="feCity" className="form-label-custom">{'Alert officer (balance rule)'}</label>
                    <Row className={'mt-2'}>
                      <Col lg={6}>
                        <Row className={'pb-2'}>
                          <Col sm={3} className={'pr-0'}>
                            <FormSelect value={violationRules.balance_rule.conditional} onChange={(event) => updateViolationRules('balance_rule', 'conditional', event.target.value)}>
                              <option value={'OR'}>OR</option>
                              <option value={'AND'}>AND</option>
                            </FormSelect>
                          </Col>
                          <Col sm={7} className={'pr-0'} style={{paddingTop: 4}}>
                            <span style={{fontSize: 11}}>outstanding balance is greater than</span>
                          </Col>
                          <Col sm={2} className={'pr-0 pl-0'}>
                            <FormInput
                              id={'violationRuleAgedBalance'}
                              value={violationRules.balance_rule.value}
                              style={{paddingLeft: 5, paddingRight: 5}}
                              onChange={(event) => updateViolationRules('balance_rule', 'value', event.target.value.replace(/\D/, ''), 'int')}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Col xs={12} className={'text-right'} style={{paddingTop: 59}}>
                          <Button
                            theme="outline-secondary"
                            className="mr-1"
                            onClick={() => removeBalanceRule()}
                          >Remove</Button>
                          {!violationRules?.aged_balance_rule && (
                            <Button
                              theme="outline-accent"
                              onClick={() => addAgedBalanceRule()}
                            >Add aged balance rule</Button>
                          )}
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
        {violationRules?.aged_balance_rule && (
          <Row className={'mb-2'}>
            <Col>
              <Card className={'p-3 pb-4'}>
                <Row>
                  <Col xs={12}>
                    <label htmlFor="feCity" className="form-label-custom">{'Alert officer (aged balance rule)'}</label>
                    <Row className={'mt-2'}>
                      <Col lg={7}>
                        <Row className={'pb-2'}>
                          <Col sm={3} className={'pr-0'}>
                            <FormSelect value={violationRules.aged_balance_rule.conditional} onChange={(event) => updateViolationRules('aged_balance_rule', 'conditional', event.target.value)}>
                              <option value={'OR'}>OR</option>
                              <option value={'AND'}>AND</option>
                            </FormSelect>
                          </Col>
                          <Col sm={6} className={'pr-0'} style={{paddingTop: 4}}>
                            <span style={{fontSize: 11}}>outstanding balance is older than</span>
                          </Col>
                          <Col sm={1} className={'pr-0 pl-0'}>
                            <FormInput
                              id={'violationRuleAgedBalance'}
                              value={violationRules.aged_balance_rule.value}
                              style={{paddingLeft: 5, paddingRight: 5}}
                              onChange={(event) => updateViolationRules('aged_balance_rule', 'value', event.target.value.replace(/\D/, ''), 'int')}
                            />
                          </Col>
                          <Col sm={2} className={'pr-0'} style={{paddingTop: 4}}>
                            <span style={{fontSize: 11}}>months</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={5}>
                        <Col xs={12} className={'text-right'} style={{paddingTop: 59}}>
                          <Button
                            theme="outline-secondary"
                            className="mr-1"
                            onClick={() => removeAgedBalanceRule()}
                          >Remove</Button>
                          {!violationRules?.balance_rule && (
                            <Button
                              theme="outline-accent"
                              onClick={() => addBalanceRule()}
                            >Add balance rule</Button>
                          )}
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
        <Row className={'mt-4'}>
          <Col>
            <Form>
              <Row form>
                <Col md="12" className="form-group text-right">
                  <Button
                    className="mr-1"
                    theme="secondary"
                    onClick={() => window.location.href='/locations'}
                  >Cancel</Button>
                  <Button
                    theme="accent"
                    disabled={violationRulesNotValid()}
                    onClick={() => {
                      if (!!location) {
                        setConfigurationStage(4)
                        updateLocation()
                      } else {
                        setConfigurationStage(4)
                      }
                    }}
                  >{!!location ? 'Save' : 'Next'}</Button>
                </Col>
              </Row>                        
            </Form>
          </Col>
        </Row>
      </>
    )
  }

  const renderStage4 = () => {
    return (
      <>
        <Card className={'p-3 pb-4'}>
          <Row>
            <Col>
              <Form>
                <Row>
                  <Col xs={12}>
                    <label htmlFor="fefooterText" className="form-label-custom">Customer facing "Citation" term</label>
                    <FormInput
                      id={'titleText'}
                      placeholder={'Enter the term to replace "Citation" for customers'}
                      value={citationTerm}
                      onChange={(event) => setCitationTerm(event.target.value.substr(0, 20))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={'text-right'}>
                    <span style={{fontSize: 10}}>{20 - citationTerm.length} characters remaining</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <label htmlFor="fefooterText" className="form-label-custom">Customer facing "Warning" term</label>
                    <FormInput
                      id={'warningText'}
                      placeholder={'Enter the term to replace "Warning" for customers'}
                      value={warningTerm}
                      onChange={(event) => setWarningTerm(event.target.value.substr(0, 20))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={'text-right'}>
                    <span style={{fontSize: 10}}>{20 - warningTerm.length} characters remaining</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <label htmlFor="fefooterText" className="form-label-custom">First paragraph on printed citation</label>
                    <FormTextarea
                      id={'firstParagraph'}
                      placeholder={'Enter text to appear in the first paragraph of the printed citation'}
                      value={firstParagraph}
                      style={{height: 80}}
                      
                      onChange={(event) => setFirstParagraph(event.target.value.substr(0, 200))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={'text-right'}>
                    <span style={{fontSize: 10}}>{200 - firstParagraph.length} characters remaining</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <label htmlFor="fefooterText" className="form-label-custom">Citation contact info</label>
                    <FormInput
                      id={'footerText'}
                      placeholder={'Enter details to appear at the bottom of the printed citation'}
                      value={footerText}
                      onChange={(event) => setFooterText(event.target.value.substr(0, 100))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={'text-right'}>
                    <span style={{fontSize: 10}}>{100 - footerText.length} characters remaining</span>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
        <Row className={'mt-4'}>
          <Col>
            <Form>
              <Row form>
                <Col md="12" className="form-group text-right">
                  <Button
                    className="mr-1"
                    theme="secondary"
                    onClick={() => window.location.href='/locations'}
                  >Cancel</Button>
                  <Button
                    theme="accent"
                    //disabled={violationRulesNotValid()}
                    onClick={() => {
                      if (!!location) {
                        //setConfigurationStage(5)
                        updateLocation()
                      } else {
                        //setConfigurationStage(5)
                        addLocation()
                      }
                    }}
                  >Save</Button>
                </Col>
              </Row>                        
            </Form>
          </Col>
        </Row>
      </>
    )
  }

  const renderStage5 = () => {
    return (
      <>
        <Row className={'mb-2'}>
          <Col>
            <Card className={'p-3 pb-4'}>
              <Row style={{marginBottom: 0}}>
                <Col className={'p-4'}>Integration details</Col>
              </Row>
              <Form>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feAddress1" className="form-label-custom">Location ID</label>
                    <FormInput
                      id="feAddress1"
                      editable={false}
                      value={location.id}
                      style={{backgroundColor: '#EEEEEE'}}
                    />
                  </Col>
                </Row> 
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feAddress1" className="form-label-custom">API key</label>
                    <FormInput
                      id="feAddress1"
                      editable={false}
                      value={location.client}
                      style={{backgroundColor: '#EEEEEE'}}
                    />
                  </Col>
                </Row>  
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feAddress1" className="form-label-custom">API secret</label>
                    <FormInput
                      id="feAddress1"
                      editable={false}
                      value={props.clients?.find(cl => cl.id === location.client)?.client_secret}
                      style={{backgroundColor: '#EEEEEE'}}
                    />
                  </Col>
                </Row>
                <hr />
                <Row form>
                  <Col md="3" className={"text-center"}>
                    <img
                      id="main-logo"
                      className="d-inline-block align-top"
                      src={require("../../images/t2.png")}
                      style={{maxWidth: 200, width: "80%"}}
                      alt="T2"
                    />
                  </Col>
                  <Col md="9" className="form-group">
                    <label htmlFor="feT2LocationId" className="form-label-custom">T2 Location ID</label>
                    <FormInput
                      id="feT2LocationId"
                      value={t2LocationId}
                      placeholder={'T2 Location ID'}
                      onChange={(event) => setT2LocationId(event.target.value)}
                    />
                  </Col>
                </Row>  
                <hr />
                <Row form>
                  <Col md="3" className={"text-center"}>
                    <img
                      id="main-logo"
                      className="d-inline-block align-top"
                      src={require("../../images/spothero.png")}
                      style={{maxWidth: 200, width: "60%"}}
                      alt="Spothero"
                    />
                  </Col>
                  <Col md="9" className="form-group">
                    <label htmlFor="feSpotheroLocationId" className="form-label-custom">Spothero Location ID</label>
                    <FormInput
                      id="feSpotheroLocationId"
                      value={spotheroLocationId}
                      placeholder={'Spothero Location ID'}
                      onChange={(event) => setSpotheroLocationId(event.target.value)}
                    />
                  </Col>
                </Row>
                <hr />
                <Row form>
                  <Col md="3" className={"text-center"}>
                    <img
                      id="main-logo"
                      className="d-inline-block align-top"
                      src={require("../../images/parklync.png")}
                      style={{maxWidth: 200, width: "80%"}}
                      alt="Parklync"
                    />
                  </Col>
                  <Col md="9" className="form-group">
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="feParklyncApiKey" className="form-label-custom">Parklync API Key</label>
                        <FormInput
                          id="feParklyncApiKey"
                          value={parklyncApiKey}
                          placeholder={'Parklync API Key'}
                          onChange={(event) => setParklyncApiKey(event.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="feParklyncLocationId" className="form-label-custom">Parklync Location ID</label>
                        <FormInput
                          id="feParklyncLocationId"
                          value={parklyncLocationId}
                          placeholder={'Parklync Location ID'}
                          onChange={(event) => setParklyncLocationId(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />
                <Row form>
                  <Col md="3" className={"text-center"}>
                    <img
                      id="main-logo"
                      className="d-inline-block align-top"
                      src={require("../../images/parkwhiz.png")}
                      style={{maxWidth: 200, width: "80%"}}
                      alt="ParkWhiz"
                    />
                  </Col>
                  <Col md="9" className="form-group">
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="feParkWhizLocationId" className="form-label-custom">ParkWhiz Location ID</label>
                        <FormInput
                          id="feParkWhizLocationId"
                          value={parkWhizLocationId}
                          placeholder={'ParkWhiz Location ID'}
                          onChange={(event) => setParkWhizLocationId(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row> 
                <hr />
                <Row form>
                  <Col md="3" className={"text-center"}>
                    <img
                      id="main-logo"
                      className="d-inline-block align-top"
                      src={require("../../images/parkpliant.png")}
                      style={{maxWidth: 200, width: "80%"}}
                      alt="Parkpliant"
                    />
                  </Col>
                  <Col md="9" className="form-group">
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="feParkpliantUsername" className="form-label-custom">Parkpliant Username</label>
                        <FormInput
                          id="feParkpliantUsername"
                          value={parkpliantUsername}
                          placeholder={'Parkpliant Username'}
                          onChange={(event) => setParkpliantUsername(event.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="feParkpliantPassword" className="form-label-custom">Parkpliant Password</label>
                        <FormInput
                          id="feParkpliantPassword"
                          value={parkpliantPassword}
                          placeholder={'Parkpliant Password'}
                          onChange={(event) => setParkpliantPassword(event.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="feParkpliantIanaTimezone" className="form-label-custom">Iana Timezone <span style={{textTransform: 'none'}}>(ex. America/New_York)</span></label>
                        <FormInput
                          id="feParkpliantIanaTimezone"
                          value={parkpliantIanaTimezone}
                          placeholder={'Iana Timezone (ex. America/New_York)'}
                          onChange={(event) => setParkpliantIanaTimezone(event.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="feParkpliantSendDays" className="form-label-custom">Parkpliant sending delay (defaults to 90 days)</label>
                        <FormInput
                          id="feParkpliantSendDays"
                          value={parkpliantSendDays}
                          placeholder={'Parkpliant sending delay (defaults to 90 days)'}
                          onChange={(event) => setParkpliantSendDays(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row> 
                
                <hr />
                <Row form>
                  <Col md="3" className={"text-center"}>
                    <img
                      id="main-logo"
                      className="d-inline-block align-top"
                      src={require("../../images/parkmobile.png")}
                      style={{maxWidth: 200, width: "80%"}}
                      alt="Parkmobile"
                    />
                  </Col>
                  <Col md="9" className="form-group">
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="feParkmobileUsername" className="form-label-custom">ParkMobile Username</label>
                        <FormInput
                          id="feParkmobileUsername"
                          value={parkMobileUsername}
                          placeholder={'ParkMobile Username'}
                          onChange={(event) => setParkMobileUsername(event.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="feParkmobilePassword" className="form-label-custom">ParkMobile Password</label>
                        <FormInput
                          id="feParkmobilePassword"
                          value={parkMobilePassword}
                          placeholder={'ParkMobile Password'}
                          onChange={(event) => setParkMobilePassword(event.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="feParkmobileApiKey" className="form-label-custom">ParkMobile API Key</label>
                        <FormInput
                          id="feParkmobileApiKey"
                          value={parkMobileApiKey}
                          placeholder={'ParkMobile API Key'}
                          onChange={(event) => setParkMobileApiKey(event.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="feParkmobileLocationId" className="form-label-custom">ParkMobile Zone ID</label>
                        <FormInput
                          id="feParkmobileLocationId"
                          value={parkMobileLocationId}
                          placeholder={'ParkMobile Zone ID'}
                          onChange={(event) => setParkMobileLocationId(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row> 
                             
              </Form>
            </Card>
            <Row className={'mt-4'}>
              <Col>
                <Form>
                  <Row form>
                    <Col md="12" className="form-group text-right">
                      <Button
                        className="mr-1"
                        theme="secondary"
                        onClick={() => window.location.href='/locations'}
                      >Cancel</Button>
                      <Button
                        theme="accent"
                        //disabled={violationRulesNotValid()}
                        onClick={() => {
                          if (!!location) {
                            //setConfigurationStage(5)
                            updateLocation()
                          } else {
                            //setConfigurationStage(5)
                            addLocation()
                          }
                        }}
                      >Save</Button>
                    </Col>
                  </Row>                        
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <Container fluid className="main-content-container px-0">
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        {!props.removeTitles && (
          <Row noGutters className="page-header py-4">
            <Col xs="12" sm="8">
              <PageTitle sm="12" title={(locationId ? 'Edit' : 'Add') + ' Location - ' + subtitle} className="text-sm-left" />
            </Col>
          </Row>
        )}
        {/* First Row of Posts */}
        <Row>
          <Col className="mb-4" sm={9}>
            {renderConfiguratorStage()}
          </Col>
          <Col sm={3}>
            <Card className={'p-0'}>
              <Container fluid style={{marginBottom: 24}}>
                <Row style={{marginBottom: 0}}>
                  <Col className={'p-4'}>Location configurator</Col>
                </Row>
                <Row style={{borderBottom: '1px solid #e1e5eb', cursor: (configurationStage > 1 || location ? 'pointer' : 'auto')}} onClick={() => configurationStage > 1 || location ? setConfigurationStage(1) : {}}>
                  <Col className={'p-4'}>
                    <Row>
                      <Col xs={2} className={'pr-0'}><span style={{fontSize: 15}}><HomeIcon fontSize="inherit" /></span></Col>
                      <Col xs={6} className={'pr-0 pl-0'}><span style={{fontSize: 12, fontWeight: 'bold', color: configurationStage > 1 || location ? '#22B177' : '#5a6169'}}>Address</span></Col>
                      <Col xs={2} className={'pr-0 pl-0'}><span style={{fontSize: 12}}>{(configurationStage > 1 || location) && (<FontAwesomeIcon icon={faCheck} color={'#22B177'} />)}</span></Col>
                      <Col xs={2}>{(configurationStage > 1 || location)&& (<span style={{fontSize: 14}}><FontAwesomeIcon icon={faEllipsisH} /></span>)}</Col>
                    </Row>
                    {configurationStage === 1 && (
                      <Row style={{marginTop: 12}}>
                        <Col style={{lineHeight: '11px'}}><span style={{color: '#B3B6BA', fontSize: 11}}>The address of the parking location you wish to manage.</span></Col>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row style={{borderBottom: '1px solid #e1e5eb', cursor: (configurationStage > 2 || location ? 'pointer' : 'auto')}} onClick={() => configurationStage > 2 || location ? setConfigurationStage(2) : {}}>
                  <Col className={'p-4'}>
                    <Row>
                      <Col xs={2} className={'pr-0'}><span style={{fontSize: 15}}><VerifiedUserIcon fontSize="inherit" /></span></Col>
                      <Col xs={6} className={'pr-0 pl-0'}><span style={{fontSize: 12, fontWeight: 'bold', color: configurationStage > 2 || location ? '#22B177' : '#5a6169'}}>Violations</span></Col>
                      <Col xs={2} className={'pr-0 pl-0'}><span style={{fontSize: 12}}>{(configurationStage > 2 || location) && (<FontAwesomeIcon icon={faCheck} color={'#22B177'} />)}</span></Col>
                      <Col xs={2}>{(configurationStage > 2 || location) && (<span style={{fontSize: 14}}><FontAwesomeIcon icon={faEllipsisH} /></span>)}</Col>
                    </Row>
                    {configurationStage === 2 && (
                      <Row style={{marginTop: 12}}>
                        <Col style={{lineHeight: '11px'}}><span style={{color: '#B3B6BA', fontSize: 11}}>Enter your violation and payment rules for the location. Entering an amount of $0 will create a warning only violation. For no aged write off enter 0 days.</span></Col>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row style={{borderBottom: '1px solid #e1e5eb', cursor: (configurationStage > 3 || location ? 'pointer' : 'auto')}} onClick={() => configurationStage > 3 || location ? setConfigurationStage(3) : {}}>
                  <Col className={'p-4'}>
                    <Row>
                      <Col xs={2} className={'pr-0'}><span style={{fontSize: 15}}><AnnouncementIcon fontSize="inherit" /></span></Col>
                      <Col xs={6} className={'pr-0 pl-0'}><span style={{fontSize: 12, fontWeight: 'bold', color: configurationStage > 3 || location ? '#22B177' : '#5a6169'}}>Officer alerts</span></Col>
                      <Col xs={2} className={'pr-0 pl-0'}><span style={{fontSize: 12}}>{(configurationStage > 3 || location) && (<FontAwesomeIcon icon={faCheck} color={'#22B177'} />)}</span></Col>
                      <Col xs={2}>{(configurationStage > 3 || location) && (<span style={{fontSize: 14}}><FontAwesomeIcon icon={faEllipsisH} /></span>)}</Col>
                    </Row>
                    {configurationStage === 3 && (
                      <Row style={{marginTop: 12}}>
                        <Col style={{lineHeight: '11px'}}><span style={{color: '#B3B6BA', fontSize: 11}}>Set your rule for scofflaw to alert the officer when they scan a vehicle that matches these rules.</span></Col>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row style={{borderBottom: '1px solid #e1e5eb', cursor: (configurationStage > 4 || location ? 'pointer' : 'auto')}} onClick={() => configurationStage > 4 || location ? setConfigurationStage(4) : {}}>
                  <Col className={'p-4'}>
                    <Row>
                      <Col xs={2} className={'pr-0'}><span style={{fontSize: 15}}><PrintIcon fontSize="inherit" /></span></Col>
                      <Col xs={6} className={'pr-0 pl-0'}><span style={{fontSize: 12, fontWeight: 'bold', color: configurationStage > 4 || location ? '#22B177' : '#5a6169'}}>Print settings</span></Col>
                      <Col xs={2} className={'pr-0 pl-0'}><span style={{fontSize: 12}}>{(configurationStage > 4 || location) && (<FontAwesomeIcon icon={faCheck} color={'#22B177'} />)}</span></Col>
                      <Col xs={2}>{(configurationStage > 4 || location) && (<span style={{fontSize: 14}}><FontAwesomeIcon icon={faEllipsisH} /></span>)}</Col>
                    </Row>
                    {configurationStage === 4 && (
                      <Row style={{marginTop: 12}}>
                        <Col style={{lineHeight: '11px'}}><span style={{color: '#B3B6BA', fontSize: 11}}>Enter your location specific text settings.</span></Col>
                      </Row>
                    )}
                  </Col>
                </Row>
                {location?.id && (
                  <Row style={{borderBottom: '1px solid #e1e5eb', cursor: (configurationStage > 5 || location ? 'pointer' : 'auto')}} onClick={() => configurationStage > 5 || location ? setConfigurationStage(5) : {}}>
                    <Col className={'p-4'}>
                      <Row>
                        <Col xs={2} className={'pr-0'}><span style={{fontSize: 15}}><NoteAddIcon fontSize="inherit" /></span></Col>
                        <Col xs={6} className={'pr-0 pl-0'}><span style={{fontSize: 12, fontWeight: 'bold', color: configurationStage > 5 || location ? '#22B177' : '#5a6169'}}>Integrations</span></Col>
                        <Col xs={2} className={'pr-0 pl-0'}><span style={{fontSize: 12}}>{(configurationStage > 5 || location) && (<FontAwesomeIcon icon={faCheck} color={'#22B177'} />)}</span></Col>
                        <Col xs={2}>{(configurationStage > 5 || location) && (<span style={{fontSize: 14}}><FontAwesomeIcon icon={faEllipsisH} /></span>)}</Col>
                      </Row>
                      {configurationStage === 5 && (
                        <Row style={{marginTop: 12}}>
                          <Col style={{lineHeight: '11px'}}><span style={{color: '#B3B6BA', fontSize: 11}}>Find your API credentials here.</span></Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                )}
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  )
  
}

export default EditLocation;
