import firebase from 'firebase';

/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */
export const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      blob.name = fileName;
      resolve(blob);
    }, 'image/png', 1);
    canvas.toDataURL();
  });
};

/**
 * Upload image to Firebase storage
 *
 * @param image
 * @param targetPath
 * @param callback
 */
export const uploadImageToStorage = (image, targetPath, callback) => {
  const storageRef = firebase.storage().ref(
    `/${targetPath}/${firebase.auth().currentUser.uid}_${Date.now()}.png`,
  );
  storageRef
    .put(image)
    .then(async snapshot => {
      if (snapshot.state === 'success') {
        let downloadUrl = await storageRef.getDownloadURL();
        callback(downloadUrl)
      }
    })
    .catch(error => console.log(error));
};

export const accessCheck = (user, section) => {
  let adminBlocked = ['clients', 'settings'];
  let officerBlocked = ['users', 'clients', 'settings', 'citations', 'tenants', 'payments'];
  let tenantBlocked = ['dashboard', 'locations', 'users', 'clients', 'settings', 'citations', 'tenants', 'payments'];
  if (user.type === 'super') {
    return true;
  }
  if (user.type === 'admin' && adminBlocked.indexOf(section) !== -1) {
    return false;
  }
  if (user.type === 'officer' && officerBlocked.indexOf(section) !== -1) {
    return false;
  }
  if (user.type === 'tenant' && tenantBlocked.indexOf(section) !== -1) {
    return false;
  }
  return true;
}
