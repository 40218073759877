/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
  FormInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "shards-react";
import moment from 'moment-timezone'
import { accessCheck } from "../../utils/tools";
import PageTitle from "../../components/common/PageTitle";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../../components/common/Pagination";
import Back from "../../components/common/Back";

const Zones = (props) => {

  let {locationId} = useParams();
  const [location, setLocation] = useState(props.locations.find(location => location.id === locationId));
  const [zones, setZones] = useState(location?.zones);
  const [sortBy, setSortBy] = useState('nameAsc')
  const [search, setSearch] = useState('')
  const [showAddZoneModal, setShowAddZoneModal] = useState(false)
  const [name, setName] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize] = useState(25)
  const [selectedZone, setSelectedZone] = useState(null)

  useEffect(() => {
    document.title = 'Manage zones: EnforcePlus admin';
    !accessCheck(props.user, 'locations') && (window.location.href = '/')
  }, [])

  useEffect(() => {
    setLocation(props.locations?.find(location => location.id === locationId))
    setZones(props.locations?.find(location => location.id === locationId)?.zones)
  }, [props.locations])

  const filterZonelist = () => {
    if (zones?.length > 0) {
      let sortedZonelist = [...zones];
      if (search !== '') {
        sortedZonelist = sortedZonelist.filter(item => (
          item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        ))
      }
      
      if (sortBy === 'createdAtDesc') {
        sortedZonelist = sortedZonelist.sort((a, b) => a.created_at && b.created_at && moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1);
      }
      if (sortBy === 'createdAtAsc') {
        sortedZonelist = sortedZonelist.sort((a, b) => a.created_at && b.created_at && moment(a.created_at).isBefore(moment(b.created_at)) ? -1 : 1);
      }
      if (sortBy === 'createdByAsc') {
        sortedZonelist = sortedZonelist.sort((a, b) => a.created_by.localeCompare(b.created_by));
      }
      if (sortBy === 'createdByDesc') {
        sortedZonelist = sortedZonelist.sort((a, b) => b.created_by.localeCompare(a.created_by));
      }
      if (sortBy === 'nameAsc') {
        sortedZonelist = sortedZonelist.sort((a, b) => a.name.localeCompare(b.name));
      }
      if (sortBy === 'nameDesc') {
        sortedZonelist = sortedZonelist.sort((a, b) => b.name.localeCompare(a.name));
      }
      return sortedZonelist
    }
    
  }

  const zoneListAction = (item, action) => {
    if (action === 'remove_zone') {
      let confirm = window.confirm('Are you sure you want to remove this zone from the location?')
      if (confirm) {
        props.removeZoneFromLocation(location, item)
      }
    }
    if (action === 'edit_zone') {
      setSelectedZone(item)
      setShowAddZoneModal(true)
    }
    // if (action === 'view_citation') {
    //   window.open(WEB_PAYMENT_URL + '/citation/' + citation.violation_number)
    // }
    // if (action === 'mark_paid') {
    //   let tempCitations = [...citations]
    //   let index = tempCitations.findIndex(cit => cit.id === citation.id)
    //   tempCitations[index].status = "paid"
    //   tempCitations[index].payment_on = moment.utc().toISOString()
    //   tempCitations[index].payment = {
    //     type: 'other',
    //     amount: parseFloat(citation.violations_total).toFixed(2)
    //   }
    //   props.markAsPaid(citation)
    //   setCitations(tempCitations)
    // }
    // if (action === 'view_dispute') {
    //   //window.open(WEB_PAYMENT_URL + '/citation/' + citation.violation_number)
    // }
  }

  const isValid = () => {
    let isValid = true
    if (name?.length < 3) {
      isValid = false
    }
    return isValid
  }

  // declare all characters
  const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  const generateString = (length) => {
    let result = '';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const addZone = () => {
    let data = {
      name: name,
      id: generateString(20),
      created_by: props.user.name,
      created_at: moment.utc().toISOString(),
      active: true,
    }
    props.addZoneToLocation(location, data)
    setName('')
    setShowAddZoneModal(false)
  }

  const editZone = () => {
    let data = {
      name: name,
      id: selectedZone.id,
      created_by: selectedZone.created_by,
      created_at: selectedZone.created_at,
      active: selectedZone.active,
    }
    props.editLocationZone(location, data)
    setName('')
    setSelectedZone(null)
    setShowAddZoneModal(false)
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}

      {/* First Row of Posts */}
      <Row>
        <Col>
          <Row noGutters className="page-header py-4">
            <PageTitle xs="12" sm="6" title={"Manage zones: " + location.name} className="text-sm-left mb-3" />
            <Col xs="12" sm="6" className='text-right'>
              <Row>
                <Col xs="1"></Col>
                <Col className='text-right pl-0'>
                  <Button
                    theme="accent"
                    onClick={() => setShowAddZoneModal(true)}
                  >Add a zone</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Back />
            </Col>
          </Row>
          {!zones || zones?.length === 0 ? (
            <>
              <Card small className="mb-4 mt-4">
                <Row>
                  <Col xs={12} style={{paddingTop: 48, paddingBottom: 32, textAlign: 'center'}}>
                    <span style={{textAlign: 'center', fontWeight: 'bold', marginBottom: 12}}>Zones allow you to permit a vehicle to everywhere or only a specific area of your parking location.</span><br />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} style={{paddingBottom: 64, textAlign: 'center'}}>
                    <Button
                      theme="accent"
                      onClick={() => setShowAddZoneModal(true)}
                    >Add a zone</Button>
                  </Col>
                </Row>
              </Card>
            </>
          ) : (
            <>
              <Card small className="mb-4 mt-4">
                <CardHeader>
                  <Row>
                    <Col xs={8}>&nbsp;</Col>
                    <Col xs={4} className='text-right pl-0'>
                      <FormInput type={'text'} className={'form-control'} placeholder={'Search'} value={search} onChange={event => setSearch(event.target.value)} />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <table className="table mb-0">
                    <thead className="bg-none">
                      <tr>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'nameAsc' ? 'nameAsc' : 'nameDesc')} style={{cursor: 'pointer'}}>Zone</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div>Location</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'createdAtDesc' ? 'createdAtDesc' : 'createdAtAsc')} style={{cursor: 'pointer'}}>Date created</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'createdByDesc' ? 'createdByDesc' : 'createdByAsc')} style={{cursor: 'pointer'}}>Created by</div>
                        </th>
                        <th scope="col" className="border-0">
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {zones && filterZonelist()?.length > 0 && filterZonelist()?.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize)?.map(item => {
                        return (
                          <tr key={item.id}>
                            <td style={{paddingTop: 24}}>
                              {item.name}
                            </td>
                            <td style={{paddingTop: 24}} className="d-none d-md-table-cell">
                              {location.name}
                            </td>
                            <td style={{paddingTop: 24}}>
                              {moment(item.created_at).format('MM/DD/Y h:mm a')}
                            </td>
                            <td style={{paddingTop: 24}}>
                              {item.created_by}
                            </td>
                            <td className="text-right">
                              <FormSelect id="feInputState" onChange={event => {zoneListAction(item, event.target.value); event.target.value = ''}}>
                                <option value={''}>Actions</option>
                                <option value={'edit_zone'}>Edit zone</option>
                                <option value={'remove_zone'}>Remove zone</option>           
                              </FormSelect>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Pagination
                data={filterZonelist()}
                pageSize={pageSize}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}      
              />
            </>
          )}
          
        </Col>
      </Row>
      <Modal size={'md'} open={showAddZoneModal}>
        <ModalHeader toggle={() => setShowAddZoneModal(false)}>{selectedZone ? 'Edit zone ' + selectedZone.name : 'Add a zone'}</ModalHeader>
        <ModalBody className={'p-3 pt-4 pb-4'}>
          <Row form className={'mb-2'}>
            <Col xs={7} className="form-group">
              <label className="form-label-custom">Zone name</label>
              <FormInput type='text' value={name ? name : selectedZone?.name} onChange={(event) => setName(event.target.value)} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{display: 'block !important'}}>
          <Row fluid>
            <Col xs={6} className={'text-left'}>
              <Button
                theme="outline-secondary"
                onClick={() => setShowAddZoneModal(false)}
                className={'mr-2'}
              >Cancel</Button>
            </Col>
            <Col xs={6} className={'text-right'}>
              <Button
                theme="accent"
                disabled={!isValid()}
                onClick={() => {
                  selectedZone ? editZone() : addZone()
                }}
              >Save</Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>            
    </Container>
  );
}

export default Zones;
