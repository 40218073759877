// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  loadingLocations: false,
  loadedLocations: false,
  locations: [],
  addingLocation: false,
  addedLocation: false,
  updatingLocation: false,
  updatedLocation: false,
  location: null,
  loadingWhitelist: false,
  loadedWhitelist: false,
  whitelist: [],
  loadingVehicleActivities: false,
  loadedVehicleActivities: false,
  vehicleActivities: []
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_LOCATIONS:
      return {
        ...state, loadingLocations: true, loadedLocations: false
      };
    case actionConstants.LOADED_LOCATIONS:
      return {
        ...state, loadingLocations: false, loadedLocations: true, locations: action.payload
      };
    case actionConstants.ADDING_LOCATION:
      return {
        ...state, addingLocation: true, addedLocation: false
      };
    case actionConstants.ADDED_LOCATION:
      return {
        ...state, addingLocation: false, addedLocation: true, location: action.payload
      };
    case actionConstants.UPDATING_LOCATION:
      return {
        ...state, updatingLocation: true, updatedLocation: false
      };
    case actionConstants.UPDATED_LOCATION:
      return {
        ...state, updatingLocation: false, updatedLocation: true, location: action.payload
      };  
    case actionConstants.CLEAR_ADDED_LOCATION:
      return {
        ...state, addingLocation: false, addedLocation: false, updatingLocation: false, updatedLocation: false
      };
    case actionConstants.LOADING_WHITELIST:
      return {
        ...state, loadingWhitelist: true, loadedWhitelist: false
      };
    case actionConstants.LOADED_WHITELIST:
      return {
        ...state, loadingWhitelist: false, loadedWhitelist: true, whitelist: action.payload
      };
    case actionConstants.CLEAR_WHITELIST:
      return {
        ...state, whitelist: []
      };
    case actionConstants.LOADING_VEHICLE_ACTIVITIES:
      return {
        ...state, loadingVehicleActivities: true, loadedVehicleActivities: false
      };
    case actionConstants.LOADED_VEHICLE_ACTIVITIES:
      return {
        ...state, loadingVehicleActivities: false, loadedVehicleActivities: true, vehicleActivities: action.payload
      };  
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getLocation = ({
  loadingLocations, loadedLocations, locations, addingLocation, addedLocation, location, updatingLocation, updatedLocation, loadingWhitelist, loadedWhitelist, whitelist, loadingVehicleActivities, loadedVehicleActivities, vehicleActivities
}) => ({
  loadingLocations,
  loadedLocations,
  locations,
  addingLocation,
  addedLocation,
  location,
  updatingLocation,
  updatedLocation,
  loadingWhitelist,
  loadedWhitelist,
  whitelist,
  loadingVehicleActivities,
  loadedVehicleActivities,
  vehicleActivities
});
