/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import {
  Alert,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Col,
  Form,
  FormFeedback,
  Button,
  FormInput,
  FormTextarea,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import {useParams} from "react-router-dom";
import { getUserLocale } from 'get-user-locale';
//import 'react-flags-select/css/react-flags-select.css';
import PageTitle from "../../components/common/PageTitle";
import { accessCheck } from "../../utils/tools";

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();

const EditUser = (props) => {
  let {userId} = useParams();
  const [user, setUser] = useState(props.users.find(user => user.id === userId));
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [client, setClient] = useState(props.user.type === 'super' ? null : props.clients[0].id);
  const [type, setType] = useState('admin');
  const [active, setActive] = useState(true);
  const [validName, setValidName] = useState(null);
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(null);
  const params = (new URL(document.location)).searchParams;
  const returnTo = params.get('return-to')

  useEffect(() => {
    document.title = 'Users: EnforcePlus admin';
    !accessCheck(props.user, 'users') && (window.location.href = '/')
  }, [])

  useEffect(() => {
    if (user) {
      setName(user.name || '')
      setEmail(user.email || '')
      setClient(user.client || null)
      setType(user.type || 'admin')
      setActive(!!user.active)
      setValidName(user.name.length > 2)
      setValidEmail(() => validateEmail(user.email))
    }
  }, [user])

  useEffect(() => {
    if (userId) {
      setUser(props.users.find(user => user.id === userId))
    }
  }, [props.users, userId])

  const validateEmail = (email) => {
    let valid = false;
    if (email.length > 0) {
      valid = (/^\w+([.\+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
    }
    if (props.users.find(thisUser => thisUser.email.toLowerCase() === email.toLowerCase() && thisUser.id !== user?.id)) {
      valid = false;
    }
    return valid
  }

  const validatePassword = (password) => {
    if (password.length > 7) {
      return true
    }
    return false
  }

  return (
    <Container fluid className="main-content-container px-0">
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        {!props.removeTitles && (
          <Row noGutters className="page-header py-4">
            <Col xs="12" sm="8">
              <PageTitle sm="12" title={(userId ? 'Edit' : 'Add') + ' User'} subtitle={'Users'} className="text-sm-left" />
            </Col>
          </Row>
        )}
        {/* First Row of Posts */}
        <Row>
          <Col className="mb-4">
            <Card className={'mb-0'}>
              <Container fluid>
                <Row>
                  <Col lg={6}>
                    <ListGroup flush>
                      <ListGroupItem className="p-3">
                        <Row>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feName">Name</label>
                                  <FormInput
                                    id='feName'
                                    onChange={event => setName(event.target.value)}
                                    placeholder='Name'
                                    value={name}
                                    invalid={validName === false}
                                    onBlur={() => setValidName(name.length > 2)}
                                  />
                                  <FormFeedback>Please enter a valid name.</FormFeedback>
                                </Col>
                              </Row>               
                            </Form>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feEmail">Email address</label>
                                  <FormInput
                                    id='feEmail'
                                    onChange={event => setEmail(event.target.value)}
                                    placeholder='Email address'
                                    value={email}
                                    invalid={validEmail === false}
                                    disabled={user?.id && props.user.id !== user?.id}
                                    onBlur={() => setValidEmail(() => validateEmail(email))}
                                  />
                                  <FormFeedback>Invalid email address or email already in use.</FormFeedback>
                                  {user?.id && props.user.id !== user?.id && (
                                    <p style={{marginBottom: 8, marginTop: 4}}>Note: You can only edit your own email address. App only users will need to use the app to change their email.</p>
                                  )}
                                </Col>
                              </Row>               
                            </Form>
                          </Col>
                        </Row>
                        {(!user || !user.id) && (
                          <Row>
                            <Col>
                              <Form>
                                <Row form>
                                  <Col md="12" className="form-group">
                                    <label htmlFor="fePassword">Set password</label>
                                    <FormInput
                                      id='fePassword'
                                      type={'password'}
                                      onChange={event => setPassword(event.target.value)}
                                      placeholder='Password'
                                      value={password}
                                      invalid={validPassword === false}
                                      onBlur={() => setValidPassword(() => validatePassword(password))}
                                    />
                                    <FormFeedback>Password must be a minimum of 8 characters.</FormFeedback>
                                  </Col>
                                </Row>               
                              </Form>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feRole">Role</label>
                                  <FormSelect id="feRole" value={type} onChange={event => setType(event.target.value)}>
                                    {props.user.type === 'super' && (
                                      <option value={'super'}>Super Admin</option>
                                    )}
                                    <option value={'admin'}>Admin</option>
                                    <option value={'officer'}>Enforcement officer</option>
                                  </FormSelect>
                                </Col>
                              </Row>               
                            </Form>
                          </Col>
                        </Row>
                        {props.user.type === 'super' && type !== 'super' && (
                          <Row>
                            <Col>
                              <Form>
                                <Row form>
                                  <Col md="12" className="form-group">
                                    <label htmlFor="feClient">Client</label>
                                    <FormSelect id="feClient" value={client} onChange={event => setClient(event.target.value)}>
                                    <option value={null}>Select</option>
                                      {props.clients.filter(client => ((!user || !user.id) && client.active) || (user && user.id && client)).sort((a, b) => a.name.localeCompare(b.name)).map(client => (
                                        <option value={client.id}>{client.name}</option>
                                      ))}
                                    </FormSelect>
                                  </Col>
                                </Row>               
                              </Form>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feName">Active</label>
                                  <FormCheckbox
                                      toggle
                                      checked={active}
                                      onChange={() => setActive(!active)}
                                    >
                                  </FormCheckbox>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                <Button
                                  className="mr-1"
                                  theme="secondary"
                                  onClick={() => props.onCancel ? props.onCancel() : window.location.href='/users'}
                                >Cancel</Button>
                                <Button
                                  theme="accent"
                                  disabled={!validName || !validEmail || ((!user || !user.id) && !validPassword) || !client }
                                  onClick={() => {
                                    if (!!user) {
                                      props.editUser(user, {name, email, client, type, active}, () => window.location.href='/users')
                                    } else {
                                      props.addUser({name, email, password, client, type, active}, () => window.location.href='/users')
                                    }
                                  }}
                                >Save</Button>
                                </Col>
                              </Row>                        
                            </Form>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                  <Col lg="6"></Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default EditUser;
