/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import {
  Alert,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Col,
  Form,
  FormFeedback,
  Button,
  FormInput,
  FormTextarea,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import {useParams} from "react-router-dom";
import { getUserLocale } from 'get-user-locale';
import ReactFlagsSelect from 'react-flags-select';
//import 'react-flags-select/css/react-flags-select.css';
import PageTitle from "../../components/common/PageTitle";
import LogoComponent from "../../components/user-profile/LogoComponent";
import { accessCheck } from "../../utils/tools";

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();

const EditClient = (props) => {
  let {clientId} = useParams();
  const [client, setClient] = useState(props.clients.find(client => client.id === clientId));
  const [name, setName] = useState('');
  const [active, setActive] = useState(true);
  const [logo, setLogo] = useState(null);
  const [primaryColor, setPrimaryColor] = useState(null);
  const [clients, setClients] = useState(props.clients);
  const [validName, setValidName] = useState(null);
  const [addedClient, setAddedClient] = useState(false);
  const [language, setLanguage] = useState('US')
  const [urlString, setUrlString] = useState('');
  const [allowCheckIn, setAllowCheckIn] = useState(false)
  const params = (new URL(document.location)).searchParams;
  const returnTo = params.get('return-to')

  useEffect(() => {
    props.getLiveClients(props.user.id)
    !accessCheck(props.user, 'clients') && (window.location.href = '/') 
  }, [])

  useEffect(() => {
    if (client) {
      setName(client.name || '')
      setLogo(client.logo || null)
      setActive(!!client.active)
      setPrimaryColor(client.primary_color || null)
      setValidName(client.name.length > 2)
      setLanguage(client.language || 'US')
      setUrlString(client.url_string || null)
      setAllowCheckIn(!!client.allow_check_in)
    }
  }, [client])

  useEffect(() => {
    if (clientId) {
      setClient(props.clients.find(client => client.id === clientId))
    }
  }, [props.clients, clientId])

  useEffect(() => {
    setClients(props.clients)
  }, [props.clients])

  useEffect(() => {
    if (props.addedClient) {
      setAddedClient(true);
      setTimeout(() => setAddedClient(false), 10000);
    }
  }, [props.addedClient]);

  return (
    <Container fluid className="main-content-container px-0">
      {/*addedClient && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> {name} has been succesfully added!
        </Alert>
      )*/}
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        {!props.removeTitles && (
          <Row noGutters className="page-header py-4">
            <PageTitle sm="7" title={(clientId ? 'Edit' : 'Add') + ' Client'} subtitle={'Clients'} className="text-sm-left" />
            <Col xs="12" sm="5" className='text-right'>
              <Row>    
                <Col className='text-right pl-0'>
                  <Button
                    theme="outline-secondary"
                    className={'mb-2'}
                    onClick={() => window.location.href='/edit-client-integrations/' + clientId}
                  >Integrations</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {/* First Row of Posts */}
        <Row>
          <Col className="mb-4">
            <Card className={'mb-0'}>
              <Container fluid>
                <Row>
                  <Col lg={6}>
                    <ListGroup flush>
                      <ListGroupItem className="p-3">
                        <Row>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feName">Name</label>
                                  <FormInput
                                    id='feName'
                                    onChange={event => setName(event.target.value)}
                                    placeholder='Name'
                                    value={name}
                                    invalid={validName === false}
                                    onBlur={() => setValidName(name.length > 2)}
                                  />
                                  <FormFeedback>Please enter a valid name.</FormFeedback>
                                </Col>
                              </Row>               
                            </Form>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feName">Default language</label>
                                  <ReactFlagsSelect
                                    selected={language}
                                    countries={["US", "ES", "GB"]}
                                    customLabels={{"US": "English","ES": "Espanyol", "GB": "English"}}
                                    placeholder="Select language"
                                    onSelect={code => setLanguage(code)}
                                    //style={{border: '1px solid #e1e5eb'}}
                                    selectButtonClassName="menu-flags-button"
                                  />
                                </Col>
                              </Row>               
                            </Form>
                          </Col>
                        </Row> */}
                        <LogoComponent data={client} setDataLogo={setLogo} />
                        <Row style={{marginTop: 24}}>
                          <Col>
                            <div>
                              <label>
                                Select your primary color
                              </label>
                              <Row className={'mb-3'} style={{marginTop: 8}}>
                                <div className={'col-3'}>
                                  <div onClick={() => setPrimaryColor('#000000')} style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#000000', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#000000' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#727679')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#727679', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#727679' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#C5AB5E')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#C5AB5E', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#C5AB5E' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#C23742')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#C23742', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#C23742' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                              </Row>
                              <Row className={'mb-3'}>
                                <div onClick={() => setPrimaryColor('#458102')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#458102', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#458102' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#0A2345')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#0A2345', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#0A2345' && (
                                      <i className={'fa fa-check text-center'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#63285B')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#63285B', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#63285B' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#2D79CF')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#2D79CF', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#2D79CF' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                              </Row>
                              <Row className={'mb-3'}>
                                <div onClick={() => setPrimaryColor('#CB5799')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#CB5799', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#CB5799' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#E05A2B')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#E05A2B', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#E05A2B' && (
                                      <i className={'fa fa-check text-center'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#804C9E')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#804C9E', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#804C9E' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                                <div onClick={() => setPrimaryColor('#872764')} className={'col-3'}>
                                  <div style={{width: 40, height: 40, borderRadius: 20, backgroundColor: '#872764', textAlign: 'center', paddingTop: 12}}>
                                    {primaryColor === '#872764' && (
                                      <i className={'fa fa-check'} style={{color: '#FFFFFF', fontSize: 18}}></i>
                                    )}
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feUrlString">Pay.enforceplus.com suffix (max 16 chars)</label>
                                  <FormInput
                                    id='feUrlString'
                                    onChange={event => setUrlString(event.target.value.replace(/[^a-z\-]+/gim, '').toLowerCase().substr(0, 16))}
                                    placeholder='Suffix'
                                    value={urlString}
                                    
                                  />
                                  <FormFeedback>Please enter a suffix.</FormFeedback>
                                </Col>
                              </Row>               
                            </Form>
                          </Col>
                        </Row>
                        <Row style={{marginTop: 16, marginBottom: 16}}>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feName">Active</label>
                                  <FormCheckbox
                                      toggle
                                      checked={active}
                                      onChange={() => setActive(!active)}
                                    >
                                    
                                  </FormCheckbox>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                        <Row style={{marginTop: 16, marginBottom: 16}}>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="feName">Allow location check-ins</label>
                                  <FormCheckbox
                                      toggle
                                      checked={allowCheckIn}
                                      onChange={() => setAllowCheckIn(!allowCheckIn)}
                                    >
                                  </FormCheckbox>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form>
                              <Row form>
                                <Col md="12" className="form-group">
                                <Button
                                  className="mr-1"
                                  theme="secondary"
                                  onClick={() => props.onCancel ? props.onCancel() : window.location.href='/clients'}
                                >Cancel</Button>
                                <Button
                                  theme="accent"
                                  disabled={!validName}
                                  onClick={() => {
                                    if (!!client) {
                                      props.editClient(client, {name, primaryColor, logo, active, urlString, allowCheckIn}, () => window.location.href='/clients')
                                    } else {
                                      props.addClient({name, primaryColor, logo, active, urlString, allowCheckIn}, () => window.location.href='/clients')
                                    }
                                  }}
                                >Save</Button>
                                </Col>
                              </Row>                        
                            </Form>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                  <Col lg="6"></Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default EditClient;
