import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Button,
  Container,
  Row,
  Col,
} from "shards-react";
import ReactCrop from 'react-image-crop';
import {getCroppedImg, uploadImageToStorage} from '../../utils/tools';
import 'react-image-crop/dist/ReactCrop.css';

const LogoComponent = ({ data, setDataLogo, labelAddition }) => {
  const [logo, setLogo] = useState((data && data.logo) || null);
  const [editingLogo, setEditingLogo] = useState(false);
  const [crop, setCrop] = useState({
    unit: '%',
    height: 50,
    width: 50,
  });
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageFile, setImageFile] = useState(false);

  const onImageLoaded = image => {
    setImageRef(image);
  };

  const onFileChange = event => {
    // Update the state
    setImageFile(URL.createObjectURL(event.target.files[0]));
    setImageLoaded(true);
  };

  return (
    <div>
      <Row>
        <Col>
          <label htmlFor="feName">Logo {labelAddition}</label>
          <div className="mb-3 mx-auto">
            {(croppedImage || logo) && (
              <img
                src={croppedImage || logo}
                alt={'Logo'}
                width="110"
              />
            )}
          </div>
          {!editingLogo ? (
            <a onClick={() => setEditingLogo(true)} style={{cursor: 'pointer'}}>Click to edit logo</a>
          ) : (
            <div>
              <strong className="text-muted d-block mb-2">
                {!imageLoaded ? 'Upload logo' : 'Crop logo'}
              </strong>
              {!imageLoaded && (
                <div>
                  <div className="custom-file mb-3">
                    <input type="file" className="custom-file-input" id="customFile" onChange={onFileChange}/>
                    <label className="custom-file-label" htmlFor="customFile">
                      Choose file...
                    </label>
                  </div>
                  <div>
                    <Button
                      size={'sm'}
                      theme={"danger"}
                      onClick={() => setEditingLogo(false)}
                      >Cancel</Button>
                  </div>
                </div>
              )}
              {imageLoaded && (
                <div className={'mb-4'}>
                  <ReactCrop
                    src={imageFile}
                    crop={crop}
                    onChange={newCrop => setCrop(newCrop)}
                    onComplete={async (crop, percentCrop) => {
                      if (imageRef && crop) {
                        let croppedImageUrl = await getCroppedImg(imageRef, crop, 'output.png')
                        setCroppedImageBlob(croppedImageUrl);
                        let reader = new FileReader();
                        reader.readAsDataURL(croppedImageUrl);
                        reader.onloadend = function() {
                          let base64data = reader.result;
                          setCroppedImage(base64data)
                        };
                      }
                    }}
                    circularCrop={false}
                    onImageLoaded={onImageLoaded}
                  />
                  <Button
                    size={'sm'}
                    className={'mr-2'}
                    theme={"primary"}
                    onClick={() => uploadImageToStorage(croppedImageBlob, 'logos', (url) => {
                      setDataLogo(url);
                      setLogo(url);
                      setEditingLogo(false);
                      setImageLoaded(false);
                      setImageFile(null);
                      setImageRef(null);
                    })}
                  >Save</Button>
                  <Button
                    size={'sm'}
                    theme={"danger"}
                    onClick={() => {
                      setEditingLogo(false);
                      setImageLoaded(false);
                      setImageFile(null);
                      setCroppedImage(null);
                      setCroppedImageBlob(null);
                      setImageRef(null);
                    }}
                  >Cancel</Button>
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
};

LogoComponent.propTypes = {
  /**
   * The user details object.
   */
  user: PropTypes.object
};

LogoComponent.defaultProps = {
  userDetails: {
    name: "Sierra Brooks",
    logo: require("./../../images/avatars/0.jpg"),
    jobTitle: "Project Manager",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Description",
    metaValue:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?"
  }
};

export default LogoComponent;
