import Citation from './Citation';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getClient, getLocation, getCitation} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, users} = getUser(state);
  const {location, locations, loadingVehicleActivities, loadedVehicleActivities, vehicleActivities} = getLocation(state);
  const {citations, citation} = getCitation(state);

  return {
    user,
    users,
    location,
    locations,
    citations,
    loadingVehicleActivities,
    loadedVehicleActivities,
    vehicleActivities,
    citation
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Citation);
