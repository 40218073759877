/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component } from "react";
import firebase from "firebase";
import { getUserLocale } from 'get-user-locale';
import {
  Container,
  Row,
  Col,
  Card,
  Button, Form, FormInput, FormGroup, FormFeedback
} from "shards-react";
import { WEBSITE_URL } from "../../environmentVariables";

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();
class ForgotPassword extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    email: null,
    sentPassword: false,
  };

  render() {
    return (
      <Container fluid className="px-0" style={{fontWeight: 200}}>
        <Row style={{marginTop: 130}}>
          <Col md="6" className="mx-auto">
            <Card small className="mb-4" style={{maxWidth: 400, marginLeft: 'auto', marginRight: 'auto', paddingLeft: 18, paddingRight: 18, paddingBottom: 32}}>
              <Container>
                <Row>
                  <Col className={'text-center'} style={{paddingTop: 60, paddingBottom: 16}}>
                    <img 
                      src={require('../../images/enforceplus-logo.png')}
                      style={{ width: 150 }}  
                    />
                  </Col>
                </Row>
                <Row>
                  {this.state.sentPassword ? (
                    <Col style={{paddingTop: 8, paddingBottom: 8}}>
                      <span style={{fontSize: 18, fontWeight: 500}}>Thank you</span><br />
                      <span style={{fontSize: 14, color: '#AAAAAA'}}>You have been sent a password reset to your email address. Click the button below to return to sign in page.</span>
                    </Col>
                  ) : (
                    <Col style={{paddingTop: 8, paddingBottom: 8}}>
                      <span style={{fontSize: 18, fontWeight: 500}}>Password reset</span><br />
                      <span style={{fontSize: 14, color: '#AAAAAA'}}>We will send you a link to reset your password.</span>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col style={{paddingTop: 8, paddingBottom: 8}}>
                    <Form>
                      <FormGroup>
                        <label htmlFor="#email" style={{fontSize: 10}}>EMAIL ADDRESS</label>
                        <FormInput id="#email" placeholder="Email address" onChange={event => this.setState({email: event.target.value})}/>
                      </FormGroup>
                      <FormGroup>
                        {!this.state.sentPassword ? (
                          <Button
                            style={{width: '100%'}}
                            onClick={() => {
                              this.props.sendPasswordResetEmail(this.state.email);
                              this.setState({sentPassword: true})
                            }}
                            disabled={!this.state.email}
                          >Reset password</Button>
                        ) : (
                          <Button
                            style={{width: '100%'}}
                            onClick={() => window.location.href = '/login'}
                          >Back to sign in</Button>
                        )}
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center" style={{paddingTop: 8, paddingBottom: 8}}>
                    <span style={{fontSize: 12}}>Remembered your password? <a href="/login" style={{fontWeight: 300}}>Back to login</a></span>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
        <Row>
          <Container style={{maxWidth: 400, fontSize: 11, fontWeight: 300}}>
            <Row>
              <Col className="text-right" style={{paddingLeft: 4, paddingRight: 4}}><a href={WEBSITE_URL + "/terms"} target="_blank">Terms of use</a></Col>
              <Col className="text-center" style={{paddingLeft: 4, paddingRight: 0}}><a href={WEBSITE_URL + "/privacy"} target="_blank">Privacy policy</a></Col>
              <Col className="text-left" style={{paddingLeft: 0, paddingRight: 4}}><a href={WEBSITE_URL + "/terms"} target="_blank">Website</a></Col>
            </Row>
            <Row style={{marginTop: 12}}>
              <Col className="text-center">&copy; 2023 EnforcePlus LLC</Col>
            </Row>
          </Container>
        </Row>
      </Container>
    );
  }
}

export default ForgotPassword;
