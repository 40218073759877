/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
  FormInput,
} from "shards-react";
import { accessCheck } from "../../utils/tools";
import moment from 'moment-timezone'
import { WEB_PAYMENT_URL } from "../../environmentVariables";

import PageTitle from "../../components/common/PageTitle";
import Pagination from "../../components/common/Pagination";
import ActivityIndicator from "../../components/common/ActivityIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

const Warnings = (props) => {

  const [citations, setCitations] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState(!searchParams.get("view") ? 'all' : searchParams.get("view"));
  const [client, setClient] = useState('all');
  const [location, setLocation] = useState('all');
  const [sortBy, setSortBy] = useState('dateDesc')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize] = useState(25)

  useEffect(() => {
    props.getAllCitations()
    document.title = 'Warnings: EnforcePlus admin';
    !accessCheck(props.user, 'citations') && (window.location.href = '/')
    props.clearCitation()
  }, [])

  useEffect(() => {
    setCitations(props.warnings)
  }, [props.warnings])

  const filterCitations = () => {
    let sortedCitations = [...citations];
    if (search !== '') {
      sortedCitations = sortedCitations.filter(citation => (
        citation.violation_number.toLowerCase().indexOf(search.toLowerCase()) !== -1
        || citation.license_plate.toLowerCase().indexOf(search.toLowerCase()) !== -1
        || citation.license_plate.toLowerCase().indexOf(search.toLowerCase()) !== -1
        || props.users?.find(user => user.id === citation.user)?.name?.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ))
    }
    if (status !== 'all') {
      if (status === 'paid') {
        sortedCitations = sortedCitations.filter(citation => citation.payment_on)
      } else if (status === 'closed') {
        sortedCitations = sortedCitations.filter(citation => citation.status === 'closed')
      } else if (status === 'void') {
        sortedCitations = sortedCitations.filter(citation => citation.status === 'void')
      } else if (status === 'unpaid') {
        sortedCitations = sortedCitations.filter(citation => !citation.payment_on && citation.status !== 'closed' && citation.status !== 'void')
      } else {
        sortedCitations = sortedCitations.filter(citation => citation.dispute?.status === 'open' && !citation.payment_on)
      }
    }
    if (client !== 'all') {
      sortedCitations = sortedCitations.filter(citation => citation.client === client)
    }
    if (location !== 'all') {
      sortedCitations = sortedCitations.filter(citation => citation.location === location)
    }
    if (sortBy === 'dateDesc') {
      sortedCitations = sortedCitations.sort((a, b) => a.created_at && b.created_at && moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1);
    }
    if (sortBy === 'dateAsc') {
      sortedCitations = sortedCitations.sort((a, b) => a.created_at && b.created_at && moment(a.created_at).isBefore(moment(b.created_at)) ? -1 : 1);
    }
    if (sortBy === 'licenseAsc') {
      sortedCitations = sortedCitations.sort((a, b) => a.license_plate.localeCompare(b.license_plate));
    }
    if (sortBy === 'licenseDesc') {
      sortedCitations = sortedCitations.sort((a, b) => b.license_plate.localeCompare(a.license_plate));
    }
    if (sortBy === 'idAsc') {
      sortedCitations = sortedCitations.sort((a, b) => a.violation_number.localeCompare(b.violation_number));
    }
    if (sortBy === 'idDesc') {
      sortedCitations = sortedCitations.sort((a, b) => b.violation_number.localeCompare(a.violation_number));
    }
    if (sortBy === 'locationAsc') {
      sortedCitations = sortedCitations.sort((a, b) => a.location_name.localeCompare(b.location_name));
    }
    if (sortBy === 'locationDesc') {
      sortedCitations = sortedCitations.sort((a, b) => b.location_name.localeCompare(a.location_name));
    }
    if (sortBy === 'userAsc') {
      sortedCitations = sortedCitations.sort((a, b) => props.users.find(user => user.id === a.user)?.name?.localeCompare(props.users.find(user => user.id === b.user)?.name));
    }
    if (sortBy === 'userDesc') {
      sortedCitations = sortedCitations.sort((a, b) => props.users.find(user => user.id === b.user)?.name?.localeCompare(props.users.find(user => user.id === a.user)?.name));
    }
    if (sortBy === 'totalAsc') {
      sortedCitations = sortedCitations.sort((a, b) => (a.payment_on ? parseFloat(a.payment.amount).toFixed(2) : parseFloat(a.violations_total).toFixed(2)) > (b.payment_on ? parseFloat(b.payment.amount).toFixed(2) : parseFloat(b.violations_total).toFixed(2)) ? 1 : -1);
    }
    if (sortBy === 'totalDesc') {
      sortedCitations = sortedCitations.sort((a, b) => (a.payment_on ? parseFloat(a.payment.amount).toFixed(2) : parseFloat(a.violations_total).toFixed(2)) > (b.payment_on ? parseFloat(b.payment.amount).toFixed(2) : parseFloat(b.violations_total).toFixed(2)) ? -1 : 1);
    }
    if (sortBy === 'statusAsc') {
      sortedCitations = sortedCitations.sort((a, b) => (a?.payment_on ? 'paid' : a?.dispute?.status === 'open' ? 'disputed' : 'unpaid').localeCompare(b?.payment_on ? 'paid' : b?.dispute?.status === 'open' ? 'disputed' : 'unpaid'));
    }
    if (sortBy === 'statusDesc') {
      sortedCitations = sortedCitations.sort((a, b) => (b?.payment_on ? 'paid' : b?.dispute?.status === 'open' ? 'disputed' : 'unpaid').localeCompare(a?.payment_on ? 'paid' : a?.dispute?.status === 'open' ? 'disputed' : 'unpaid'));
    }

    return sortedCitations.slice()
  }

  const citationAction = (citation, action) => {
    if (action === 'view_warning') {
      window.location.href = './citation/' + citation.violation_number
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}

      {/* First Row of Posts */}
      <Row>
        <Col>
          <Row noGutters className="page-header py-4">
            <PageTitle xs="12" sm="4" title="Warnings" className="text-sm-left mb-3" />
            <Col xs="12" sm="8" className='text-right'>
              <Row>
                <Col xs="1"></Col>
                <Col className='text-right pl-0 pr-1'>
                  {props.user.type === 'super' && (
                    <FormSelect id="feClients" onChange={event => setClient(event.target.value)}>
                      <option value={'all'}>All clients</option>
                      {props.clients?.sort((a, b) => a.name.localeCompare(b.name)).map(client => (
                        <option value={client.id}>{client.name}</option>
                      ))}
                    </FormSelect>
                  )}
                </Col>
                <Col className='text-right pl-0'>
                  <FormSelect value={location} id="feLocations" onChange={event => setLocation(event.target.value)}>
                    <option value={'all'}>All locations</option>
                    {props.locations?.sort((a, b) => a.name.localeCompare(b.name)).map(location => (
                      <option value={location.id}>{location.name}</option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
            </Col>
          </Row>
          {props.gettingAllCitations ? (
            <Row>
              <Col className={'text-center'}>
                <ActivityIndicator />
              </Col>
            </Row>
          ) : (
            <>  
              <Card small className="mb-4 mt-4">
                <CardHeader>
                  <Row>
                    <Col xs={8}>&nbsp;</Col>
                    <Col xs={4} className='text-right pl-0'>
                      <FormInput type={'text'} className={'form-control'} placeholder={'Search'} value={search} onChange={event => setSearch(event.target.value)} />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <table className="table mb-0">
                    <thead className="bg-none">
                      <tr>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'licenseAsc' ? 'licenseAsc' : 'licenseDesc')} style={{cursor: 'pointer'}}>License plate</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'idAsc' ? 'idAsc' : 'idDesc')} style={{cursor: 'pointer'}}>#ID</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'dateDesc' ? 'dateDesc' : 'dateAsc')} style={{cursor: 'pointer'}}>Date/Time</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div>Warning type</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'locationAsc' ? 'locationAsc' : 'locationDesc')} style={{cursor: 'pointer'}}>Location</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'userAsc' ? 'userAsc' : 'userDesc')} style={{cursor: 'pointer'}}>Issued by</div>
                        </th>
                        <th scope="col" className="border-0">
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.warnings && filterCitations().length > 0 && filterCitations().slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize).map(citation => {
                        let warningString = ''
                        citation.violations.map((viol, idx) => {warningString = warningString + (idx > 0 ? ', ' : '') + viol.name})
                        return (
                          <>
                            <tr key={citation.id}>
                              <td style={{paddingTop: 24}}>
                                {citation.license_plate}
                              </td>
                              <td style={{paddingTop: 24}} className={"d-none d-md-table-cell"}>
                                {citation.violation_number}
                              </td>
                              <td style={{paddingTop: 24}}>
                                {moment(citation.created_at).format('MM/DD/Y h:mm a')}
                              </td>
                              <td style={{paddingTop: 24}}>
                                {warningString}
                              </td>
                              <td style={{paddingTop: 24}} className={"d-none d-md-table-cell"}>
                                {citation.location_name}
                              </td>
                              <td style={{paddingTop: 24}} className={"d-none d-md-table-cell"}>
                                {props.users.find(user => user.id === citation.user)?.name}
                              </td>
                              {/* <td className={"text-right d-none d-md-table-cell"}>
                                <FormSelect id="feInputState" onChange={event => {citationAction(citation, event.target.value); event.target.value = ''}}>
                                  <option value={''}>Actions</option>
                                  <option value={'view_warning'}>View warning</option>
                                </FormSelect>
                              </td> */}
                            </tr>
                            {/* <tr className="d-md-none d-xs-table-row">
                              <td className="border-top-0"></td>
                              <td colSpan={3} className={"text-right border-top-0"}>
                                <FormSelect id="feInputState" onChange={event => {citationAction(citation, event.target.value); event.target.value = ''}}>
                                  <option value={''}>Actions</option>
                                  <option value={'view_warning'}>View warning</option>
                                </FormSelect>
                              </td>
                            </tr> */}
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Pagination
                data={filterCitations()}
                pageSize={pageSize}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}      
              />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Warnings;
