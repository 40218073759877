import EditClientIntegrations from './EditClientIntegrations';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getClient} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user} = getUser(state);
  const {addingClient, addedClient, clients, client, loadedClients, loadingClients} = getClient(state);

  return {
    user,
    addingClient,
    addedClient,
    clients,
    client,
    loadedClients,
    loadingClients,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditClientIntegrations);
