import Tenants from './Tenants';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getClient, getLocation} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, loadingTenants, loadedTenants, tenants, addingTenant, users} = getUser(state);
  const {clients} = getClient(state);
  const {locations, location} = getLocation(state);

  return {
    user,
    users,
    clients,
    locations,
    location,
    loadedTenants,
    loadingTenants,
    tenants,
    addingTenant,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tenants);
