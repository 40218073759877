// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  gettingAllCitations: false,
  gotAllCitations: false,
  gettingAllPayments: false,
  gotAllPayments: false,
  citations: [],
  citation: {},
  warnings: [],
  payments: [],
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.GETTING_ALL_CITATIONS:
      return {
        ...state, gettingAllCitations: true, gotAllCitations: false
      };
    case actionConstants.GOT_ALL_CITATIONS:
      return {
        ...state, gettingAllCitations: false, gotAllCitations: true, citations: action.payload?.filter(citation => citation.ticket_type !== 'warning'), warnings: action.payload?.filter(citation => citation.ticket_type === 'warning')
      };
    case actionConstants.GETTING_ALL_PAYMENTS:
      return {
        ...state, gettingAllPayments: true, gotAllPayments: false
      };
    case actionConstants.GOT_ALL_PAYMENTS:
      return {
        ...state, gettingAllPayments: false, gotAllPayments: true, payments: action.payload?.filter(payment => payment.ticket_type !== 'warning'), warnings: action.payload?.filter(payment => payment.ticket_type === 'warning')
      };  
    case actionConstants.SET_CITATION:
      return {
        ...state, citation: action.payload
      };
    case actionConstants.CLEAR_CITATION:
      return {
        ...state, citation: {}
      };   
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getCitation = ({
  gettingAllCitations, gotAllCitations, citations, citation, warnings, gettingAllPayments, gotAllPayments, payments, 
}) => ({
  gettingAllCitations,
  gotAllCitations,
  citations,
  citation,
  warnings,
  gettingAllPayments,
  gotAllPayments,
  payments, 
});
