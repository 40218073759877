import React from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const Back = () => {
  return (
    <div onClick={() => window.history.back()} style={{cursor: 'pointer'}}>
      <span style={{fontSize: 20}}><KeyboardBackspaceIcon fontSize="inherit"/></span>&nbsp;<span style={{fontSize: 14}}>Back</span>
    </div>
  )
}

export default Back