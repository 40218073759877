import React, { useState, useEffect } from 'react';
import { Row, Col } from "shards-react";
import { ceil } from 'lodash';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Pagination = (props) => {
  const [currentPage, setCurrentPage] = useState(props.page)
  let pageSize = props.pageSize
  let itemCount = props.data?.length || 0
  let numberOfPages = ceil(itemCount / pageSize) > 0 ? ceil(itemCount / pageSize) : 1

  useEffect(() => {
    setCurrentPage(props.page)
  }, [props.page])

  const changePage = (newPage) => {
    if (newPage > 0 && newPage <= numberOfPages) {
      props.onPageChange(newPage)
    }
  }

  return (
    <Row>
      <Col>
        <nav aria-label="Page navigation example" style={{display: 'flex', justifyContent: 'center'}}>
          <ul className="pagination">
            <li className="page-item"><a className="page-link" style={{cursor: 'pointer'}} onClick={() => changePage(currentPage - 1)}>
              <ArrowBackIosNewIcon fontSize='inherit'/>
            </a></li>
            {Array.from({length: numberOfPages}, (v, i) => i).map(idx => (
              <li className="page-item"><a className="page-link" style={{cursor: 'pointer', color: currentPage === (idx + 1) ? '#21AA73' : '#5A5B5F'}} onClick={() => changePage(idx + 1)}>{idx + 1}</a></li>
            ))}
            <li className="page-item"><a className="page-link" style={{cursor: 'pointer'}} onClick={() => changePage(currentPage + 1)}>
              <ArrowForwardIosIcon fontSize='inherit'/>
            </a></li>
          </ul>
        </nav>
      </Col>
    </Row>
  )
}

export default Pagination