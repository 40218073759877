/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
} from "shards-react";
import { accessCheck } from "../../utils/tools";

import PageTitle from "../../components/common/PageTitle";

const Clients = (props) => {

  const [clients, setClients] = useState([]);
  const [status, setStatus] = useState('all');
  const [addedClient, setAddedClient] = useState(props.addedClient);
  const [updatedClient, setUpdatedClient] = useState(props.updatedClient);
  

  useEffect(() => {
    props.getLiveClients(props.user.id)
    document.title = 'Clients: EnforcePlus admin';
    !accessCheck(props.user, 'clients') && (window.location.href = '/') 
  }, [])

  useEffect(() => {
    setClients(props.clients)
  }, [props.clients])

  useEffect(() => {
    if (props.addedClient) {
      setAddedClient(true);
      setTimeout(() => {
        setAddedClient(false)
        props.clearAddedClient()
      }, 10000);
    }
    if (props.updatedClient) {
      setUpdatedClient(true);
      setTimeout(() => {
        setUpdatedClient(false)
        props.clearAddedClient()
      }, 10000);
    }
  }, [props.addedClient, props.updatedClient])

  const filterClients = () => {
    let sortedClients = clients;
    if (status !== 'all') {
      if (status === 'active') {
        sortedClients = sortedClients.filter(client => client.active)
      } else {
        sortedClients = sortedClients.filter(client => !client.active)
      }
    }
    sortedClients = sortedClients.sort((a, b) => a.name && b.name && a.name.localeCompare(b.name));
    return sortedClients
  }

  return (
    <Container fluid className="main-content-container px-0">
      {addedClient && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> {props.client.name} has been succesfully added!
        </Alert>
      )}
      {updatedClient && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> {props.client.name} has been succesfully updated!
        </Alert>
      )}
      <Container fluid className="main-content-container px-4">
        {/* First Row of Posts */}
        <Row>
          <Col>
            <Card small className="mb-4 mt-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col xs="12" sm="3">
                    <h6 className="m-0">Clients</h6>
                  </Col>
                  <Col xs="12" sm="9" className='text-right'>
                    <Row>
                      <Col xs="3"></Col>
                      <Col className='text-right pl-0 pr-1'>
                        <FormSelect id="feInputState" onChange={event => setStatus(event.target.value)}>
                          <option value={'all'}>All statuses</option>
                          <option value={'active'}>Active</option>
                          <option value={'inactive'}>Inactive</option>
                        </FormSelect>
                      </Col>
                      <Col className='text-right' xs="3">
                        <Button
                          theme="accent"
                          onClick={() => window.location.href='/add-client'}
                        >Add a client</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Name
                      </th>
                      <th scope="col" className="border-0">
                        Status
                      </th>
                      <th scope="col" className="border-0">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterClients().length > 0 && filterClients().map(client => {
                      return (
                        <tr key={client.id}>
                          <td>
                            {client.name}
                          </td>
                          <td>
                            {client.active ? 'Active' : 'Inactive'}
                          </td>
                          <td className="text-right">
                            <a href={"/edit-client/" + client.id}>Edit</a>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Clients;
