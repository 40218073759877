import React from "react";
import {useSelector} from 'react-redux';
import PropTypes from "prop-types";
import firebase from "firebase";
import { Container, Row, Col } from "shards-react";

import {getUser, getLocation} from '../reducers/rootReducer';

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";

const DefaultLayout = ({ children, noNavbar, noFooter }) => {
  firebase.auth().onAuthStateChanged(user => {
    if (!user) {
      window.location.href = '/login';
    }
  });
  const user = useSelector(state => getUser(state).user);
  const locations = useSelector(state => getLocation(state).locations);
  return (
    <Container fluid>
      <Row>
        <MainSidebar user={user} locations={locations} />
        <Col
          className="main-content p-0"
          lg={{ size: 10, offset: 2 }}
          md={{ size: 9, offset: 3 }}
          sm="12"
          tag="main"
        >
          {!noNavbar && <MainNavbar />}
          {children}
          {!noFooter && <MainFooter />}
        </Col>
      </Row>
    </Container>
  )
};

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayout;
