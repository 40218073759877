import firebase from 'firebase';
import { persistor } from '../App';
import * as actionConstants from '../actionConstants';
import { clearLocationState } from './locationActions';

export const authenticateWithEmailAddressAndPassword = (email, password) => {
  return dispatch => {
    dispatch({type: actionConstants.LOGGING_IN});
    firebase.auth()
      .signInWithEmailAndPassword(email.toLowerCase(), password)
      .then(user => {
        if (user) {
          dispatch(finishLogin());
        }
      })
      .catch(error => {
        dispatch({type: actionConstants.LOGIN_FAIL, payload: error});
      });
  };
};

/**
 * Send password reset email
 *
 * @param email
 * @returns {Function}
 */
export const sendPasswordResetEmail = (email) => {
  return dispatch => {
    dispatch({type: actionConstants.SENDING_PASSWORD_RESET});
    firebase.auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({
          type: actionConstants.SENT_PASSWORD_RESET
        });
      })
      .catch(error => {
        dispatch({type: actionConstants.SENT_PASSWORD_RESET, payload: error});
      });
  };
};

export const finishLogin = () => {
  return async (dispatch, getState) => {
    let currentUser = await firebase.auth().currentUser;
    dispatch({type: actionConstants.LOADING_USER});
    let user = await firebase
      .firestore()
      .collection('users')
      .doc(currentUser.uid)
      .get()
      .then(doc => {
        if (doc && doc.data().active) {
          dispatch({ type: actionConstants.LOADED_USER, payload: {id: doc.id, ...doc.data()}});
        } else {
          dispatch({type: actionConstants.LOGIN_FAIL});
        }
      })
      .catch(error => dispatch({type: actionConstants.LOGIN_FAIL}))
  }
};

export const logout = () => {
  return async (dispatch, getState) => {
    await firebase.auth().signOut()
    persistor.purge()
  }
};
