import PermittedVehicles from './PermittedVehicles';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getClient, getLocation} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, users, tenants} = getUser(state);
  const {clients, client} = getClient(state);
  const {locations, location, loadingWhitelist, loadedWhitelist, whitelist} = getLocation(state);

  return {
    user,
    users,
    clients,
    client,
    locations,
    location,
    loadingWhitelist,
    loadedWhitelist,
    whitelist,
    tenants
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PermittedVehicles);
