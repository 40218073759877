import Dashboard from './Dashboard';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getClient, getDashboard, getLocation} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, users} = getUser(state);
  const {clients} = getClient(state);
  const {dailyScans, citations, scans, gettingCitations, gettingDailyScans, gettingVisitorCount, warnings, gettingDisputes, disputes} = getDashboard(state)
  const {locations} = getLocation(state);

  return {
    user,
    users,
    clients,
    locations,
    dailyScans,
    citations,
    scans,
    gettingCitations,
    gettingDailyScans,
    gettingVisitorCount,
    warnings,
    gettingDisputes,
    disputes,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
