/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { Link, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect,
  FormInput,
  FormCheckbox,
  FormTextarea,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "shards-react";
import moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import EventNoteIcon from '@mui/icons-material/EventNote';
import TimePicker from 'react-bootstrap-time-picker';
import { accessCheck } from "../../utils/tools";
import { WEB_PAYMENT_URL } from "../../environmentVariables";
import PageTitle from "../../components/common/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { CONSTANTS } from "../../constants";
import "react-datepicker/dist/react-datepicker.css";
import { InsertInvitationOutlined } from "@mui/icons-material";
import { floor } from "lodash";
import ActivityIndicator from "../../components/common/ActivityIndicator";
import Pagination from "../../components/common/Pagination";

const PermittedVehicles = (props) => {
  let {locationId} = useParams();
  const [location, setLocation] = useState(props.locations.find(location => location.id === locationId));
  const [whitelist, setWhitelist] = useState([]);
  const [sortBy, setSortBy] = useState('licenseAsc')
  const [search, setSearch] = useState('')
  const [showAddVehicleModal, setShowAddVehicleModal] = useState(false)
  const [licensePlate, setLicensePlate] = useState('')
  const [licensePlateState, setLicensePlateState] = useState(location.state)
  const [startDate, setStartDate] = useState(moment().toDate())
  const [startTime, setStartTime] = useState(moment().format("HH:mm"))
  const [howLong, setHowLong] = useState('today')
  const [untilADate, setUntilADate] = useState(false)
  const [endDate, setEndDate] = useState(moment().add(1, 'days').toDate())
  const [page, setPage] = useState(1)
  const [pageSize] = useState(25)
  const [zone, setZone] = useState(null)
  const [tenants, setTenants] = useState(null)
  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const [showImportVehicleModal, setShowImportVehicleModal] = useState(false)
  const [tenant, setTenant] = useState(null)
  const [csvFile, setCsvFile] = useState(null)
  const [showNotesModal, setShowNotesModal] = useState(false)
  const [notes, setNotes] = useState(location?.notes || null)

  useEffect(() => {
    props.getLocationWhitelist(location)
    props.getLocationTenants(location)
    document.title = 'Permitted vehicles: EnforcePlus admin';
    !accessCheck(props.user, 'locations') && (window.location.href = '/')
    return (() => props.clearWhitelist())
  }, [])

  useEffect(() => {
    setWhitelist(props.whitelist)
  }, [props.whitelist])

  useEffect(() => {
    setTenants(props.tenants)
    if (props.tenants?.length === 1) {
      setTenant(props.tenants[0])
    }
  }, [props.tenants])

  const filterWhitelist = () => {
    if (whitelist?.length > 0) {
      let sortedWhitelist = [...whitelist];
      sortedWhitelist = sortedWhitelist.filter(item => moment(item.expiry_time).isAfter(moment()))
      if (search !== '') {
        sortedWhitelist = sortedWhitelist.filter(item => (
          item.license_plate.toLowerCase().indexOf(search.toLowerCase()) !== -1
        ))
      }
      
      if (sortBy === 'startdateDesc') {
        sortedWhitelist = sortedWhitelist.sort((a, b) => a.start_time && b.start_time && moment(a.start_time).isBefore(moment(b.start_time)) ? 1 : -1);
      }
      if (sortBy === 'startdateAsc') {
        sortedWhitelist = sortedWhitelist.sort((a, b) => a.start_time && b.start_time && moment(a.start_time).isBefore(moment(b.start_time)) ? -1 : 1);
      }
      if (sortBy === 'expiresDesc') {
        sortedWhitelist = sortedWhitelist.sort((a, b) => a.expiry_time && b.expiry_time && moment(a.expiry_time).isBefore(moment(b.expiry_time)) ? 1 : -1);
      }
      if (sortBy === 'expiresAsc') {
        sortedWhitelist = sortedWhitelist.sort((a, b) => a.expiry_time && b.expiry_time && moment(a.expiry_time).isBefore(moment(b.expiry_time)) ? -1 : 1);
      }
      if (sortBy === 'licenseAsc') {
        sortedWhitelist = sortedWhitelist.sort((a, b) => a.license_plate.localeCompare(b.license_plate));
      }
      if (sortBy === 'licenseDesc') {
        sortedWhitelist = sortedWhitelist.sort((a, b) => b.license_plate.localeCompare(a.license_plate));
      }
      return sortedWhitelist
    }
    
  }

  const whitelistAction = (item, action) => {
    if (action === 'remove_vehicle') {
      let confirm = window.confirm('Are you sure you want to remove this vehicle from the list?')
      if (confirm) {
        props.removeFromWhitelist(location, item)
      }
    }
    if (action === 'edit_vehicle') {
      editVehicle(item)
    }
  }

  const editVehicle = (item) => {
    setSelectedVehicle(item)
    setLicensePlate(item.license_plate)
    setLicensePlateState(item.license_plate_state)
    setStartDate(moment(item.start_time).toDate())
    setStartTime(moment(item.start_time).format("HH:mm"))
    setHowLong('today')
    setUntilADate(true)
    setEndDate(moment(item.expiry_time).toDate())
    setZone(item.zone)
    setShowAddVehicleModal(true)
  }

  const isValid = () => {
    let isValid = true
    if (licensePlate?.length < 3) {
      isValid = false
    }
    return isValid
  }

  const isImportValid = () => {
    let isValid = true
    if (!csvFile || csvFile.type !== 'text/csv') {
      isValid = false
    }
    return isValid
  }

  const calculateTimeFromNumbers = (numbers) => {
    let hours = Math.floor(numbers / 3600)
    let minutes = floor((numbers - (hours * 3600)) / 60)
    return {hours, minutes}
  }

  const addVehicleToWhitelist = () => {
    let data = {
      license_plate: licensePlate,
      license_plate_state: licensePlateState,
      zone: zone || null,
    }
    let adjustedStartDate = moment(startDate)
    adjustedStartDate = adjustedStartDate.set('hour', calculateTimeFromNumbers(startTime).hours)
    adjustedStartDate = adjustedStartDate.set('minute', calculateTimeFromNumbers(startTime).minutes)
    adjustedStartDate = adjustedStartDate.set('second', 0)

    let adjustedEndDate = moment(endDate)
    if (!untilADate) {
      if (howLong === 'today') {
        adjustedEndDate = moment(adjustedStartDate)
      }
      if (howLong === 'untilRemoved') {
        adjustedEndDate = moment('9990-12-31 00:00:00')
      }
      if (howLong !== 'today' && howLong !== 'untilRemoved') {
        adjustedEndDate = moment(adjustedStartDate).add(1, howLong)
      }
    }
    adjustedEndDate = adjustedEndDate.set('hour', 23)
    adjustedEndDate = adjustedEndDate.set('minute', 59)
    adjustedEndDate = adjustedEndDate.set('second', 59)

    data.start_time = moment(adjustedStartDate).utc().toISOString()
    data.expiry_time = moment(adjustedEndDate).utc().toISOString()
    data.space_number = "2"
    data.source = props.user.name
    props.addVehicleToWhitelist(location, data)
    setLicensePlate('')
    setLicensePlateState(location.state)
    setStartDate(moment().toDate())
    setStartTime(moment().format("HH:mm"))
    setHowLong('today')
    setUntilADate(false)
    setEndDate(moment().add(1, 'days').toDate())
    setZone(null)
    setShowAddVehicleModal(false)
  }

  const editVehicleOnWhitelist = () => {
    let data = {
      license_plate: licensePlate,
      license_plate_state: licensePlateState,
      zone: zone || null,
    }
    let adjustedStartDate = moment(startDate)
    adjustedStartDate = adjustedStartDate.set('hour', calculateTimeFromNumbers(startTime).hours)
    adjustedStartDate = adjustedStartDate.set('minute', calculateTimeFromNumbers(startTime).minutes)
    adjustedStartDate = adjustedStartDate.set('second', 0)

    let adjustedEndDate = moment(endDate)
    if (!untilADate) {
      if (howLong === 'today') {
        adjustedEndDate = moment(adjustedStartDate)
      }
      if (howLong === 'untilRemoved') {
        adjustedEndDate = moment('9990-12-31 00:00:00')
      }
      if (howLong !== 'today' && howLong !== 'untilRemoved') {
        adjustedEndDate = moment(adjustedStartDate).add(1, howLong)
      }
    }
    adjustedEndDate = adjustedEndDate.set('hour', 23)
    adjustedEndDate = adjustedEndDate.set('minute', 59)
    adjustedEndDate = adjustedEndDate.set('second', 59)

    data.start_time = moment(adjustedStartDate).utc().toISOString()
    data.expiry_time = moment(adjustedEndDate).utc().toISOString()
    data.space_number = "2"
    data.source = props.user.name
    props.editVehicleOnWhitelist(selectedVehicle, location, data)
    setSelectedVehicle(null)
    setLicensePlate('')
    setLicensePlateState(location.state)
    setStartDate(moment().toDate())
    setStartTime(moment().format("HH:mm"))
    setHowLong('today')
    setUntilADate(false)
    setEndDate(moment().add(1, 'days').toDate())
    setZone(null)
    setShowAddVehicleModal(false)
  }

  const saveLocationNotes = () => {
    props.editLocationNotes(location, notes)
    setShowNotesModal(false)
  }

  const importVehiclesToWhitelist = () => {
    let data = {
      zone: zone || null,
      tenant: tenant || null,
      csvFile: csvFile
    }
    let adjustedStartDate = moment(startDate)
    adjustedStartDate = adjustedStartDate.set('hour', calculateTimeFromNumbers(startTime).hours)
    adjustedStartDate = adjustedStartDate.set('minute', calculateTimeFromNumbers(startTime).minutes)
    adjustedStartDate = adjustedStartDate.set('second', 0)

    let adjustedEndDate = moment(endDate)
    if (!untilADate) {
      if (howLong === 'today') {
        adjustedEndDate = moment(adjustedStartDate)
      }
      if (howLong === 'untilRemoved') {
        adjustedEndDate = moment('9990-12-31 00:00:00')
      }
      if (howLong !== 'today' && howLong !== 'untilRemoved') {
        adjustedEndDate = moment(adjustedStartDate).add(1, howLong)
      }
    }
    adjustedEndDate = adjustedEndDate.set('hour', 23)
    adjustedEndDate = adjustedEndDate.set('minute', 59)
    adjustedEndDate = adjustedEndDate.set('second', 59)

    data.start_time = moment(adjustedStartDate).utc().toISOString()
    data.expiry_time = moment(adjustedEndDate).utc().toISOString()
    data.space_number = "2"
    data.source = props.user.name
    props.importVehiclesToWhitelist(location, data)
    setLicensePlate('')
    setLicensePlateState(location.state)
    setStartDate(moment().toDate())
    setStartTime(moment().format("HH:mm"))
    setHowLong('today')
    setUntilADate(false)
    setEndDate(moment().add(1, 'days').toDate())
    setZone(null)
    setTenant(null)
    setShowImportVehicleModal(false)
  }
  
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}

      {/* First Row of Posts */}
      <Row>
        <Col>
          <Row noGutters className="page-header py-4">
            <PageTitle xs="12" sm="5" title={"Permitted vehicles: " + location.name} className="text-sm-left mb-3" />
            <Col xs="12" sm="7" className='text-right'>
              <Row>
                
                <Col className='text-right pl-0'>
                  {accessCheck(props.user, 'tenants') && (
                    <Button
                      theme="outline-secondary"
                      onClick={() => window.location.href='/location/' + location.id + '/tenants'}
                      className={'mr-2 mb-2'}
                    >Manage tenants</Button>
                  )}
                  <Button
                    theme="outline-secondary"
                    onClick={() => window.location.href='/location/' + location.id + '/zones'}
                    className={'mr-2 mb-2'}
                  >Manage zones</Button>
                  <Button
                    theme="outline-secondary"
                    onClick={() => window.location.href='/edit-location/' + location.id}
                    className={'mr-2 mb-2'}
                  >Edit location</Button>
                  <Button
                    theme="outline-secondary"
                    onClick={() => setShowImportVehicleModal(true)}
                    className={'mr-2 mb-2'}
                  >Import vehicles</Button>
                  <Button
                    theme="outline-secondary"
                    onClick={() => setShowNotesModal(true)}
                    className={'mr-2 mb-2'}
                  >Notes</Button>
                  <Button
                    theme="accent"
                    className={'mb-2'}
                    onClick={() => setShowAddVehicleModal(true)}
                  >Add vehicle</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {props.loadingWhitelist ? (
            <Row>
              <Col className={'text-center'}>
                <ActivityIndicator />
              </Col>
            </Row>
          ) : whitelist?.length === 0 ? (
            <>
              <Card small className="mb-4 mt-4">
                <Row>
                  <Col xs={12} style={{paddingTop: 48, paddingBottom: 32, textAlign: 'center'}}>
                    <span style={{textAlign: 'center', fontWeight: 'bold', marginBottom: 12}}>Get started and add some permitted vehicles</span><br />
                    <span style={{textAlign: 'center', fontSize: 13}}>Permitted vehicles will not trigger a potential<br />violation when the license plate is scanned by<br />the enforcement officer.</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} style={{paddingBottom: 64, textAlign: 'center'}}>
                    <Button
                      theme="accent"
                      className={'mr-2'}
                      onClick={() => setShowAddVehicleModal(true)}
                    >Add vehicle</Button>
                    <Button
                      theme="outline-secondary"
                      onClick={() => setShowImportVehicleModal(true)}
                    >Import vehicles</Button>
                  </Col>
                </Row>
              </Card>
            </>
          ) : (
            <>
              <Card small className="mb-4 mt-4">
                <CardHeader>
                  <Row>
                    <Col xs={8}></Col>
                    <Col xs={4} className='text-right pl-0'>
                      <FormInput type={'text'} className={'form-control'} placeholder={'Search'} value={search} onChange={event => setSearch(event.target.value)} />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <table className="table mb-0">
                    <thead className="bg-none">
                      <tr>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'licenseAsc' ? 'licenseAsc' : 'licenseDesc')} style={{cursor: 'pointer'}}>License plate</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'idAsc' ? 'idAsc' : 'idDesc')} style={{cursor: 'pointer'}}>Source</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'startdateDesc' ? 'startdateDesc' : 'startdateAsc')} style={{cursor: 'pointer'}}>Start time</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'expiresDesc' ? 'expiresDesc' : 'expiresAsc')} style={{cursor: 'pointer'}}>Expires</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'idAsc' ? 'idAsc' : 'idDesc')} style={{cursor: 'pointer'}}>Zone</div>
                        </th>
                        {/* <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'amountAsc' ? 'amountAsc' : 'amountDesc')} style={{cursor: 'pointer'}}>Amount paid</div>
                        </th> */}
                        <th scope="col" className="border-0">
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.whitelist && filterWhitelist()?.length > 0 && filterWhitelist()?.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize)?.map(item => {
                        return (
                          <tr key={item.id}>
                            <td style={{paddingTop: 24}}>
                              {item.license_plate}
                            </td>
                            <td style={{paddingTop: 24}} className="d-none d-md-table-cell">
                              {item.source}
                            </td>
                            <td style={{paddingTop: 24}}>
                              {moment(item.start_time).format('MM/DD/Y h:mm a')}
                            </td>
                            <td style={{paddingTop: 24}}>
                              {moment(item.expiry_time).diff(moment(), 'year') <= 0 ? (
                                moment(item.expiry_time).format('MM/DD/Y h:mm a')
                              ) : (
                                'Permit'
                              )}
                            </td>
                            <td style={{paddingTop: 24}}>
                              {location.zones?.find(zone => zone.id === item.zone)?.name}
                            </td>
                            {/* <td style={{paddingTop: 24}} className="d-none d-md-table-cell">
                              
                            </td> */}
                            <td className="text-right">
                              <FormSelect id="feInputState" onChange={event => {whitelistAction(item, event.target.value); event.target.value = ''}}>
                                <option value={''}>Actions</option>
                                <option value={'edit_vehicle'}>Edit vehicle</option>   
                                <option value={'remove_vehicle'}>Remove vehicle</option>           
                              </FormSelect>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Pagination
                data={filterWhitelist()}
                pageSize={pageSize}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}      
              />
            </>
          )}
          
        </Col>
      </Row>
      <Modal size={'md'} open={showAddVehicleModal}>
        <ModalHeader toggle={() => setShowAddVehicleModal(false)}>{selectedVehicle?.id ? 'Edit' : 'Add'} a permitted vehicle</ModalHeader>
        <ModalBody className={'p-3 pt-4 pb-4'}>
          <Row form className={'mb-2'}>
            <Col xs={7} className="form-group">
              <label className="form-label-custom">Enter the license plate</label>
              <FormInput type='text' value={licensePlate} onChange={(event) => setLicensePlate(event.target.value.toUpperCase().replace(/\s/gim, ''))} />
            </Col>
            <Col xs={5} className="form-group">
              <label className="form-label-custom">Enter the State</label>
              <FormSelect value={licensePlateState} onChange={(event) => setLicensePlateState(event.target.value)}>
                {CONSTANTS.US_STATES.map(state => (
                  <option value={state.abbreviation}>{state.name}</option>
                ))}
              </FormSelect>
            </Col>
          </Row>
          {location?.zones?.length > 0 && (
            <Row form className={'mb-2'}>
              <Col xs={7} className="form-group">
                <label className="form-label-custom">Which zone is the vehicle permitted in</label>
                <FormSelect value={zone} onChange={(event) => setZone(event.target.value)}>
                  <option value={null}>All zones</option>
                  {location?.zones?.map(zone => (
                    <option value={zone.id}>{zone.name}</option>
                  ))}
                </FormSelect>
              </Col>
              <Col xs={5} className="form-group"></Col>
            </Row>
          )}
          <Row form className={'mb-2'}>
            <Col xs={7} className="form-group">
              <label className="form-label-custom">When would you like to add the vehicle</label>
              <InputGroup seamless style={{borderRadius: 3}}>
                <InputGroupAddon type="prepend">
                  <InputGroupText>
                    <span style={{fontSize: 18}}><EventNoteIcon fontSize={'inherit'} /></span>
                  </InputGroupText>
                </InputGroupAddon>
                <DatePicker
                  className={'form-control'}
                  style={{width: '100%', borderRadius: '3px !important', border: 'none !important'}}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  todayButton="Today"
                  />
              </InputGroup>
            </Col>
            <Col xs={5} className="form-group">
              <label className="form-label-custom">&nbsp;</label>
              <TimePicker start="00:00" end="23:55" step={5} value={startTime} onChange={(time) => setStartTime(time)} />
            </Col>
          </Row>
          {!untilADate && (
            <Row form className={'mb-2'}>
              <Col xs={7} className="form-group">
                <label className="form-label-custom">How long should the vehicle be valid for?</label>
                <FormSelect value={howLong} onChange={(event) => setHowLong(event.target.value)}>
                  <option value={'today'}>Today only</option>
                  <option value={'week'}>1 week</option>
                  <option value={'month'}>1 month</option>
                  <option value={'year'}>1 year</option>
                  <option value={'untilRemoved'}>Until removed</option>
                </FormSelect>
              </Col>
              <Col xs={5} className="form-group pt-1">
                <label className="form-label-custom">&nbsp;</label>
                <FormCheckbox checked={untilADate} onChange={() => setUntilADate(!untilADate)}>until a date</FormCheckbox>
              </Col>
            </Row>
          )}
          {untilADate && (
            <Row form className={'mb-2'}>
              <Col xs={7} className="form-group">
                <label className="form-label-custom">Select an end date</label>
                <InputGroup seamless style={{borderRadius: 3}}>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <span style={{fontSize: 18}}><EventNoteIcon fontSize={'inherit'} /></span>
                    </InputGroupText>
                  </InputGroupAddon>
                  <DatePicker
                    className={'form-control'}
                    style={{width: '100%', borderRadius: '3px !important', border: 'none !important'}}
                    selected={endDate}
                    minDate={startDate}
                    onChange={(date) => setEndDate(date)}
                    />
                </InputGroup>
              </Col>
              <Col xs={5} className="form-group pt-1">
                <label className="form-label-custom">&nbsp;</label>
                <FormCheckbox checked={untilADate} onChange={() => setUntilADate(!untilADate)}>until a date</FormCheckbox>
              </Col>
            </Row>  
          )}
        </ModalBody>
        <ModalFooter style={{display: 'block !important'}}>
          <Row fluid>
            <Col xs={6} className={'text-left'}>
              <Button
                theme="outline-secondary"
                onClick={() => setShowAddVehicleModal(false)}
                className={'mr-2'}
              >Cancel</Button>
                  
            </Col>
            <Col xs={6} className={'text-right'}>
              <Button
                theme="accent"
                disabled={!isValid()}
                onClick={() => selectedVehicle?.id ? editVehicleOnWhitelist() : addVehicleToWhitelist()}
              >Save</Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>      
      <Modal size={'md'} open={showImportVehicleModal}>
        <ModalHeader toggle={() => setShowImportVehicleModal(false)}>Import permitted vehicles</ModalHeader>
        <ModalBody className={'p-3 pt-4 pb-4'}>
          {tenants?.length > 0 && (
            <Row form className={'mb-1'}>
              <Col xs={12} className="form-group">
                <label className="form-label-custom">Which tenants should the vehicles be allocated to</label>
                <FormSelect value={tenant} onChange={(event) => setTenant(event.target.value)}>
                  {tenants?.map(tenant => (
                    <option value={tenant.id}>{tenant.name}</option>
                  ))}
                </FormSelect>
              </Col>
              <Col xs={5} className="form-group"></Col>
            </Row>
          )}
          {location?.zones?.length > 0 && (
            <Row form className={'mb-1'}>
              <Col xs={12} className="form-group">
                <label className="form-label-custom">Which zone are the vehicles permitted in</label>
                <FormSelect value={zone} onChange={(event) => setZone(event.target.value)}>
                  <option value={null}>All zones</option>
                  {location?.zones?.map(zone => (
                    <option value={zone.id}>{zone.name}</option>
                  ))}
                </FormSelect>
              </Col>
              <Col xs={5} className="form-group"></Col>
            </Row>
          )}
          <Row form className={'mb-2'}>
            <Col xs={7} className="form-group">
              <label className="form-label-custom">When would you like to add the vehicles</label>
              <InputGroup seamless style={{borderRadius: 3}}>
                <InputGroupAddon type="prepend">
                  <InputGroupText>
                    <span style={{fontSize: 18}}><EventNoteIcon fontSize={'inherit'} /></span>
                  </InputGroupText>
                </InputGroupAddon>
                <DatePicker
                  className={'form-control'}
                  style={{width: '100%', borderRadius: '3px !important', border: 'none !important'}}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  todayButton="Today"
                  />
              </InputGroup>
            </Col>
            <Col xs={5} className="form-group">
              <label className="form-label-custom">&nbsp;</label>
              <TimePicker start="00:00" end="23:55" step={5} value={startTime} onChange={(time) => setStartTime(time)} />
            </Col>
          </Row>
          {!untilADate && (
            <Row form className={'mb-2'}>
              <Col xs={7} className="form-group">
                <label className="form-label-custom">How long should the vehicles be valid for?</label>
                <FormSelect value={howLong} onChange={(event) => setHowLong(event.target.value)}>
                  <option value={'today'}>Today only</option>
                  <option value={'week'}>1 week</option>
                  <option value={'month'}>1 month</option>
                  <option value={'year'}>1 year</option>
                  <option value={'untilRemoved'}>Until removed</option>
                </FormSelect>
              </Col>
              <Col xs={5} className="form-group pt-1">
                <label className="form-label-custom">&nbsp;</label>
                <FormCheckbox checked={untilADate} onChange={() => setUntilADate(!untilADate)}>until a date</FormCheckbox>
              </Col>
            </Row>
          )}
          {untilADate && (
            <Row form className={'mb-2'}>
              <Col xs={7} className="form-group">
                <label className="form-label-custom">Select an end date</label>
                <InputGroup seamless style={{borderRadius: 3}}>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <span style={{fontSize: 18}}><EventNoteIcon fontSize={'inherit'} /></span>
                    </InputGroupText>
                  </InputGroupAddon>
                  <DatePicker
                    className={'form-control'}
                    style={{width: '100%', borderRadius: '3px !important', border: 'none !important'}}
                    selected={endDate}
                    minDate={startDate}
                    onChange={(date) => setEndDate(date)}
                    />
                </InputGroup>
              </Col>
              <Col xs={5} className="form-group pt-1">
                <label className="form-label-custom">&nbsp;</label>
                <FormCheckbox checked={untilADate} onChange={() => setUntilADate(!untilADate)}>until a date</FormCheckbox>
              </Col>
            </Row>  
          )}
          <Row form className={'mb-2'}>
            <Col xs={12} className="form-group">
              <label className="form-label-custom" style={{marginBottom: 0}}>Upload your CSV file</label><br />
              <label className="form-label-custom" style={{fontSize: 10, textTransform: 'none'}}>(Two columns, "license plate" and "state" in CSV format with header row)</label>
              <FormInput type={'file'} onChange={(event) => setCsvFile(event.target.files[0])} />
            </Col>
            <Col xs={5} className="form-group"></Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{display: 'block !important'}}>
          <Row fluid>
            <Col xs={6} className={'text-left'}>
              <Button
                theme="outline-secondary"
                onClick={() => setShowImportVehicleModal(false)}
                className={'mr-2'}
              >Cancel</Button>
                  
            </Col>
            <Col xs={6} className={'text-right'}>
              <Button
                theme="accent"
                disabled={!isImportValid()}
                onClick={() => importVehiclesToWhitelist()}
              >Import</Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
      <Modal size={'md'} open={showNotesModal}>
        <ModalHeader toggle={() => setShowNotesModal(false)}>Location notes</ModalHeader>
        <ModalBody className={'p-3 pt-4 pb-2'}>
          
          <Row form className={'mb-2'}>
            <Col xs={12} className="form-group">
            <FormTextarea
              id={'notes'}
              placeholder={'Enter text to appear in the notes section of the app'}
              value={notes}
              style={{height: 150}}
              onChange={(event) => setNotes(event.target.value)}
            />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{display: 'block !important'}}>
          <Row fluid>
            <Col xs={6} className={'text-left'}>
              <Button
                theme="outline-secondary"
                onClick={() => setShowNotesModal(false)}
                className={'mr-2'}
              >Cancel</Button>
            </Col>
            <Col xs={6} className={'text-right'}>
              <Button
                theme="accent"
                onClick={() => saveLocationNotes()}
              >Save</Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>       
    </Container>
  );
}

export default PermittedVehicles;
