import Citations from './Citations';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getClient, getLocation, getCitation, getDashboard} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, users} = getUser(state);
  const {clients, client} = getClient(state);
  const {locations, location} = getLocation(state);
  const {gettingAllCitations, gotAllCitations, citations} = getCitation(state);
  const {statusFilter} = getDashboard(state)

  return {
    user,
    users,
    clients,
    client,
    locations,
    location,
    gettingAllCitations,
    gotAllCitations,
    citations,
    statusFilter
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Citations);
