import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import 'firebase/firestore';
import firebase from 'firebase';
import rootReducer from './reducers/rootReducer';
import Login from './views/Login'

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "./css/enforceplus.css";

import {FIREBASE_CONFIG} from './environmentVariables';

firebase.initializeApp(FIREBASE_CONFIG);

const middleware = [thunk];
// Logger will only run in development mode __DEV__ is a NPM global constant
if (process.env.NODE_ENV === 'development') {
  const loggerMiddleware = createLogger();
  middleware.push(loggerMiddleware);
}

const persistConfig = {
  key: "root",
  storage,
  //blacklist: ["login"], // messages will not be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middleware)));
export const persistor = persistStore(store);

export default () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <route.layout>
                    <route.component />
                  </route.layout>
                }
              />
            );
          })}
        </Routes>
      </Router>
    </PersistGate>
  </Provider>
);
