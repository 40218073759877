import ForgotPassword from './ForgotPassword';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getLogin, getUser, getClient, getLocation} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {codeSent, checkingCode, codeFailed, loggingIn, loginFail, codePassed} = getLogin(
    state,
  );
  const {loadingUser, loadedUser, user, users} = getUser(state);
  const {clients} = getClient(state);
  const {locations, loadedLocations, waypoints, loadedWaypoints} = getLocation(state);
  
  return {
    codeSent,
    checkingCode,
    codeFailed,
    codePassed,
    loggingIn,
    loginFail,
    loadingUser,
    loadedUser,
    user,
    clients,
    users,
    locations,
    loadedLocations,
    waypoints,
    loadedWaypoints
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPassword);
