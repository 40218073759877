/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import {
  Alert,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Col,
  Form,
  FormFeedback,
  Button,
  FormInput,
  FormTextarea,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import {useParams} from "react-router-dom";
import { getUserLocale } from 'get-user-locale';
import ReactFlagsSelect from 'react-flags-select';
//import 'react-flags-select/css/react-flags-select.css';
import PageTitle from "../../components/common/PageTitle";
import LogoComponent from "../../components/user-profile/LogoComponent";
import { accessCheck } from "../../utils/tools";

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();

const EditClientIntegrations = (props) => {
  let {clientId} = useParams();
  const [client, setClient] = useState(props.clients.find(client => client.id === clientId));
  const [t2Username, setT2Username] = useState(client?.t2_username);
  const [t2Password, setT2Password] = useState(client?.t2_password);
  const [t2Token, setT2Token] = useState(client?.t2_token);
  const params = (new URL(document.location)).searchParams;
  const returnTo = params.get('return-to')

  useEffect(() => {
    !accessCheck(props.user, 'clients') && (window.location.href = '/') 
  }, [])

  useEffect(() => {
    if (client) {
      setT2Username(client.t2_username || null)
      setT2Password(client.t2_password || null)
      setT2Token(client.t2_token || null)
    }
  }, [client])

  useEffect(() => {
    if (clientId) {
      setClient(props.clients.find(client => client.id === clientId))
    }
  }, [props.clients, clientId])

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      {!props.removeTitles && (
        <Row noGutters className="page-header py-4">
          <PageTitle sm="7" title={(clientId ? 'Edit' : 'Add') + ' Client Integrations'} subtitle={'Clients'} className="text-sm-left" />
        </Row>
      )}
      {/* First Row of Posts */}
      <Row>
        <Col className="mb-4">
          <Card className={'mb-0'}>
            <Container fluid>
              <Row>
                <Col lg={6}>
                  <ListGroup flush>
                    <ListGroupItem className="p-3">
                      <Row>
                        <Col>
                          <Form>
                            <Row form>
                              <Col md="12" className="form-group">
                                <label htmlFor="t2Username">T2 Username</label>
                                <FormInput
                                  id='t2Username'
                                  onChange={event => setT2Username(event.target.value)}
                                  placeholder='T2 Username'
                                  value={t2Username}
                                />
                              </Col>
                            </Row>               
                          </Form>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form>
                            <Row form>
                              <Col md="12" className="form-group">
                              <label htmlFor="t2Username">T2 Password</label>
                                <FormInput
                                  id='t2Password'
                                  onChange={event => setT2Password(event.target.value)}
                                  placeholder='T2 Password'
                                  value={t2Password}
                                />
                              </Col>
                            </Row>               
                          </Form>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form>
                            <Row form>
                              <Col md="12" className="form-group">
                              <label htmlFor="t2Username">T2 Token</label>
                                <FormInput
                                  id='t2Token'
                                  onChange={event => setT2Token(event.target.value)}
                                  placeholder='T2 Token'
                                  value={t2Token}
                                />
                              </Col>
                            </Row>               
                          </Form>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form>
                            <Row form>
                              <Col md="12" className="form-group">
                              <Button
                                className="mr-1"
                                theme="secondary"
                                onClick={() => window.location.href='/edit-client/' + clientId}
                              >Cancel</Button>
                              <Button
                                theme="accent"
                                onClick={() => props.editClientIntegrations(client, {t2Username, t2Password, t2Token}, () => window.location.href='/edit-client/' + clientId)}
                              >Save</Button>
                              </Col>
                            </Row>                        
                          </Form>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </Col>
                <Col lg="6"></Col>
              </Row>
            </Container>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default EditClientIntegrations;
