/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
  FormInput,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "shards-react";
import moment from 'moment-timezone'
import { accessCheck } from "../../utils/tools";
import PageTitle from "../../components/common/PageTitle";
import "react-datepicker/dist/react-datepicker.css";
import ActivityIndicator from "../../components/common/ActivityIndicator";
import Pagination from "../../components/common/Pagination";
import Back from "../../components/common/Back";

const Tenants = (props) => {
  let {locationId} = useParams();
  const [location, setLocation] = useState(props.locations.find(location => location.id === locationId));
  const [tenants, setTenants] = useState(null)
  const [sortBy, setSortBy] = useState('nameAsc')
  const [search, setSearch] = useState('')
  const [showAddTenantModal, setShowAddTenantModal] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize] = useState(25)
  const [selectedTenant, setSelectedTenant] = useState(null)
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [client, setClient] = useState(props.user.type === 'super' ? null : props.clients[0].id);
  const [zone, setZone] = useState(null)
  const [vehicleLimit, setVehicleLimit] = useState(1);
  const [validName, setValidName] = useState(null);
  const [validEmail, setValidEmail] = useState(true);

  useEffect(() => {
    props.getLiveTenants(props.user.id)
    document.title = 'Tenants: EnforcePlus admin';
    !accessCheck(props.user, 'tenants') && (window.location.href = '/')
  }, [])

  useEffect(() => {
    setTenants(props.tenants.filter(ten => ten.location === location.id))
  }, [props.tenants])

  const validateEmail = (email) => {
    let valid = false;
    if (email.length > 0) {
      valid = (/^\w+([.\+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
    }
    if (props.tenants.find(thisUser => thisUser.email.toLowerCase() === email.toLowerCase()) || props.users.find(thisUser => thisUser.email.toLowerCase() === email.toLowerCase())) {
      valid = false;
    }
    return valid
  }

  const filterTenantlist = () => {
    let zoneList = []
    props.locations.forEach(loc => {
      loc.zones && loc.zones.forEach(zone => {
        zoneList.push(zone)
      })
    })
    if (tenants?.length > 0) {
      let sortedTenantList = [...tenants];
      if (search !== '') {
        sortedTenantList = sortedTenantList.filter(item => (
          item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        ))
      }
      if (sortBy === 'createdByAsc') {
        sortedTenantList = sortedTenantList.sort((a, b) => a.created_by.localeCompare(b.created_by));
      }
      if (sortBy === 'createdByDesc') {
        sortedTenantList = sortedTenantList.sort((a, b) => b.created_by.localeCompare(a.created_by));
      }
      if (sortBy === 'nameAsc') {
        sortedTenantList = sortedTenantList.sort((a, b) => a.name.localeCompare(b.name));
      }
      if (sortBy === 'nameDesc') {
        sortedTenantList = sortedTenantList.sort((a, b) => b.name.localeCompare(a.name));
      }
      if (sortBy === 'zoneAsc') {
        sortedTenantList = sortedTenantList.sort((a, b) => zoneList?.length > 0 && a.zone && b.zone && zoneList.find(zone => a.zone === zone.id)?.name?.localeCompare(zoneList.find(zone => b.zone === zone.id)?.name) ? 1 : -1);
      }
      if (sortBy === 'zoneDesc') {
        sortedTenantList = sortedTenantList.sort((a, b) => zoneList?.length > 0 && a.zone && b.zone && zoneList.find(zone => b.zone === zone.id)?.name?.localeCompare(zoneList.find(zone => a.zone === zone.id)?.name) ? -1 : 1);
      }
      if (sortBy === 'locationAsc') {
        sortedTenantList = sortedTenantList.sort((a, b) => props.locations.find(loc => a.location === loc.id)?.name?.localeCompare(props.locations.find(loc => b.location === loc.id)?.name) ? 1 : -1);
      }
      if (sortBy === 'locationDesc') {
        sortedTenantList = sortedTenantList.sort((a, b) => props.locations.find(loc => b.location === loc.id)?.name?.localeCompare(props.locations.find(loc => a.location === loc.id)?.name) ? -1 : 1);
      }
      return sortedTenantList
    }
    
  }

  const closeTenantModal = () => {
    setName('')
    setEmail('')
    setZone(null)
    setVehicleLimit(1)
    setSelectedTenant(null)
    setShowAddTenantModal(false)
  }

  const tenantListAction = (item, action) => {
    if (action === 'remove_tenant') {
      let confirm = window.confirm('Are you sure you want to remove this tenant?')
      if (confirm) {
        props.softDeleteTenant(item)
      }
    }
    if (action === 'edit_tenant') {
      setSelectedTenant(item)
      setValidName(true)
      setValidEmail(true)
      setName(item.name)
      setEmail(item.email)
      setZone(item.zone)
      setVehicleLimit(item.vehicle_limit)
      setShowAddTenantModal(true)
    }
  }

  const addTenant = () => {
    let data = {
      name: name,
      email: email,
      client: props?.locations?.find(loc => loc.id === location.id)?.client,
      location: location.id,
      zone: zone,
      vehicle_limit: vehicleLimit,
      created_by: props.user.name,
      created_at: moment.utc().toISOString(),
    }
    props.addTenant(data, null)
    setName('')
    setEmail('')
    setZone(null)
    setVehicleLimit(1)
    setShowAddTenantModal(false)
    setSelectedTenant(null)
  }

  const editTenant = () => {
    let data = {
      name: name,
      location: location.id,
      zone: zone,
      vehicle_limit: vehicleLimit,
    }
    props.editTenant(selectedTenant, data)
    setName('')
    setEmail('')
    setZone(null)
    setVehicleLimit(1)
    setShowAddTenantModal(false)
    setSelectedTenant(null)
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}

      {/* First Row of Posts */}
      <Row>
        <Col>
          <Row noGutters className="page-header py-4">
            <PageTitle xs="12" sm="6" title={"Manage tenants: " + location.name} className="text-sm-left mb-3" />
            <Col xs="12" sm="6" className='text-right'>
              <Row>
                <Col xs="1"></Col>
                <Col className='text-right pl-0'>
                  <Button
                    theme="accent"
                    onClick={() => setShowAddTenantModal(true)}
                  >Add a tenant</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Back />
            </Col>
          </Row>
          {props.addingTenant || props.loadingTenants ? (
            <Row>
              <Col className={'text-center'}>
                <ActivityIndicator />
              </Col>
            </Row>
          ) : tenants?.length === 0 ? (
            <>
              <Card small className="mb-4 mt-4">
                <Row>
                  <Col xs={12} style={{paddingTop: 48, paddingBottom: 32, textAlign: 'center'}}>
                    <span style={{textAlign: 'center', fontWeight: 'bold', marginBottom: 12}}>Add your first tenant</span><br />
                    <span style={{textAlign: 'center', fontSize: 13}}>Tenants can sign in to add and manage their<br />permitted vehicles at one of your locations..</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} style={{paddingBottom: 64, textAlign: 'center'}}>
                    <Button
                      theme="accent"
                      onClick={() => setShowAddTenantModal(true)}
                    >Add a tenant</Button>
                  </Col>
                </Row>
              </Card>
            </>
          ) : (
            <>
              <Card small className="mb-4 mt-4">
                <CardHeader>
                  <Row>
                    <Col xs={8}>&nbsp;</Col>
                    <Col xs={4} className='text-right pl-0'>
                      <FormInput type={'text'} className={'form-control'} placeholder={'Search'} value={search} onChange={event => setSearch(event.target.value)} />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <table className="table mb-0">
                    <thead className="bg-none">
                      <tr>
                      <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'nameAsc' ? 'nameAsc' : 'nameDesc')} style={{cursor: 'pointer'}}>Name</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'zoneAsc' ? 'zoneAsc' : 'zoneDesc')} style={{cursor: 'pointer'}}>Zone</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'createdByDesc' ? 'createdByDesc' : 'createdByAsc')} style={{cursor: 'pointer'}}>Created by</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div>Vehicles allowed</div>
                        </th>
                        <th scope="col" className="border-0">

                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tenants && filterTenantlist()?.length > 0 && filterTenantlist()?.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize)?.map(item => {
                        return (
                          <tr key={item.id}>
                            <td style={{paddingTop: 24}}>
                              {item.name}
                            </td>
                            <td style={{paddingTop: 24}}>
                              {item.zone ? props.locations?.find(loc => item.location == loc.id)?.zones?.find(zone => zone.id === item.zone)?.name : 'All zones'}
                            </td>
                            <td style={{paddingTop: 24}} className="d-none d-md-table-cell">
                              {item.created_by}
                            </td>
                            <td style={{paddingTop: 24}}>
                              {item.vehicle_limit}
                            </td>
                            <td className="text-right">
                              <FormSelect id="feInputState" onChange={event => {tenantListAction(item, event.target.value); event.target.value = ''}}>
                                <option value={''}>Actions</option>
                                <option value={'edit_tenant'}>Edit tenant</option>
                                <option value={'remove_tenant'}>Remove tenant</option>           
                              </FormSelect>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Pagination
                data={filterTenantlist()}
                pageSize={pageSize}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}      
              />
            </>
          )}
          
        </Col>
      </Row>
      <Modal size={'md'} open={showAddTenantModal}>
        <ModalHeader
          toggle={() => closeTenantModal()}>{selectedTenant ? 'Edit tenant ' + selectedTenant.name : 'Add a tenant'}</ModalHeader>
        <ModalBody className={'p-3 pt-4 pb-4'}>
          <Row form className={'mb-2'}>
            <Col xs={6} className="form-group">
              <label className="form-label-custom">Tenant name</label>
              <FormInput
                id='feName'
                onChange={event => setName(event.target.value)}
                placeholder='Name'
                value={name}
                invalid={validName === false}
                onBlur={() => setValidName(name.length > 2)}
              />
              <FormFeedback>Please enter a valid name.</FormFeedback>
            </Col>
            <Col xs={6} className="form-group">
              <label className="form-label-custom">Email address</label>
              <FormInput
                id='feEmail'
                onChange={event => setEmail(event.target.value)}
                placeholder='Email address'
                value={email}
                disabled={selectedTenant?.id}
                invalid={validEmail === false}
                onBlur={() => setValidEmail(() => validateEmail(email))}
              />
              <FormFeedback>Invalid email address or email already in use.</FormFeedback>
            </Col>
          </Row>
          {props?.locations?.find(loc => loc.id === location.id)?.zones?.length > 0 && (
            <Row form className={'mb-2'}>
              <Col xs={7} className="form-group">
                <label className="form-label-custom">In which zone can this tenant park</label>
                <FormSelect value={zone} onChange={(event) => setZone(event.target.value)}>
                  <option value={null}>All zones</option>
                  {props?.locations?.find(loc => loc.id === location.id)?.zones?.map(zone => (
                    <option value={zone.id}>{zone.name}</option>
                  ))}
                </FormSelect>
              </Col>
              <Col xs={5} className="form-group"></Col>
            </Row>
          )}
          <Row form className={'mb-2'}>
            <Col xs={7} className="form-group">
              <label className="form-label-custom">How many permitted vehicles is the tenant allowed</label>
              <FormSelect value={vehicleLimit} onChange={(event) => setVehicleLimit(event.target.value)}>
                {[...Array(251).keys()].map(val => val > 0 ? (
                  <option value={val}>{val}</option>
                 ) : null)}
              </FormSelect>
            </Col>
            <Col xs={5} className="form-group"></Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{display: 'block !important'}}>
          <Row fluid>
            <Col xs={6} className={'text-left'}>
              <Button
                theme="outline-secondary"
                onClick={() => closeTenantModal()}
                className={'mr-2'}
              >Cancel</Button>
            </Col>
            <Col xs={6} className={'text-right'}>
              <Button
                theme="accent"
                disabled={!validName || !validEmail || !location }
                onClick={() => {
                  selectedTenant ? editTenant() : addTenant()
                }}
              >Save</Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>            
    </Container>
  );
}

export default Tenants;
