import Users from './Users';
import {ActionCreators} from '../../actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getUser, getClient} from '../../reducers/rootReducer';

const mapStateToProps = state => {
  const {user, users, addedUser, addingUser, updatingUser, updatedUser, lastUser, addingUserFailed} = getUser(state);
  const {clients} = getClient(state)

  return {
    user,
    users,
    addedUser,
    addingUser,
    updatingUser,
    updatedUser,
    clients,
    lastUser,
    addingUserFailed
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Users);
