import * as userActions from './userActions';
import * as loginActions from './loginActions';
import * as clientActions from './clientActions';
import * as locationActions from './locationActions';
import * as dashboardActions from './dashboardActions';
import * as citationActions from './citationActions';


export const ActionCreators = {
  ...userActions,
  ...loginActions,
  ...clientActions,
  ...locationActions,
  ...dashboardActions,
  ...citationActions,
};
